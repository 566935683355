import styled from 'styled-components';
import { toRem } from 'styles/v3/functions';

export const Container =
  styled.div <
  { $enabled: boolean } >
  `
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: ${toRem(3)};

  opacity: ${({ $enabled }) => ($enabled ? 1 : 0.5)};
`;

export const Icon = styled.div`
  .i-icon {
    display: flex;
  }
`;
