import { Modal } from 'antd';
import BasicButton from 'components/v3/Buttons/BasicButton';
import styled from 'styled-components';
import { BREAKPOINTS } from 'styles/v3/constants';
import { toRem } from 'styles/v3/functions';

import { COLORS } from 'styles/v3/variables';

export const StyledModal = styled(Modal)`
  .ant-modal-content {
    max-width: 4 ${toRem(20)};
    border-radius: ${toRem(24)};
    background-color: ${COLORS.grey900};
  }

  .ant-modal-header {
    border-radius: ${toRem(24)};
    background-color: ${COLORS.grey900};
  }

  .ant-tag {
    border-radius: ${toRem(24)};
    padding: ${toRem(6)} ${toRem(12)} ${toRem(6)} ${toRem(12)};
  }

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    .ant-modal-content {
      width: 100vw;
      height: 100vh;
      max-width: none;
      border-radius: 0;
      padding-top: ${toRem(72)};
    }

    .ant-modal-body {
      height: 100%;
      overflow-y: auto;
    }
  }
`;

export const ModalWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  gap: ${toRem(16)};

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    justify-content: flex-start;
    margin-bottom: ${toRem(100)};
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  gap: ${toRem(16)};
`;

export const TagsWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;

  gap: ${toRem(16)};
`;

export const UpgradeButton = styled(BasicButton)`
  width: 100%;
  font-size: ${toRem(16)};
  font-weight: 600;
  text-transform: uppercase;
  color: ${COLORS.brandPrimary};
  background-color: transparent !important;
  border-color: ${COLORS.brandPrimary};

  &:hover {
    background-color: ${COLORS.brandPrimary} !important;
    color: ${COLORS.grey1000};
  }
`;

export const SettingsButton = styled.button`
  width: ${toRem(38)};
  height: ${toRem(38)};

  border: none;
  box-shadow: none;
  padding: ${toRem(7.4)} 0;
  border-radius: ${toRem(10)};
  background-color: ${COLORS.grey700};

  cursor: pointer;

  transition: all 0.3s;

  &:hover {
    background-color: ${COLORS.grey600};
  }
`;

export const ButtonWrapper = styled.div`
  position: absolute;

  top: ${toRem(20)};
  right: ${toRem(20)};

  display: flex;
  flex-direction: column;
  gap: ${toRem(12)};

  button {
    background-color: ${COLORS.grey700};
    border-radius: ${toRem(10)};

    border: none;
  }

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    width: 100%;

    position: unset;
    align-items: flex-end;
  }
`;
