import React, { useState } from 'react';

import { BodyM, COLORS, TitleH4 } from 'styles/v3/variables';

import { NewAgeDivision } from 'admin/models/AgeDivision';
import { RadioGroup } from 'components/v3/Forms/RadioGroup/RadioGroup';
import InputSelect, { OptionsType } from 'components/v3/Forms/Select/Select';
import { MultiValue, StylesConfig } from 'react-select';
import * as S from './styles';

type AgeSectionProps = {
  ageDivisions?: NewAgeDivision[];
};

export const AgeSection = ({ ageDivisions }: AgeSectionProps) => {
  const [selectedYears, setSelectedYears] = useState<MultiValue<OptionsType>>(
    []
  );
  const [gender, setGender] = useState('');

  const yearsOptions = Array.from(
    new Set(ageDivisions?.flatMap((item) => item.years))
  ).map((value) => ({
    value,
    label: value.toString(),
  }));

  const newAgeDivisions = ageDivisions
    ?.filter((item) =>
      gender ? item.gender.toLowerCase() === gender.toLowerCase() : true
    )
    .filter((item) =>
      selectedYears.length > 0
        ? selectedYears.some((year) =>
            item.years.includes(year.value as number)
          )
        : true
    );

  return (
    <S.SectionWrapper>
      <TitleH4 $isUpper>Age divisions</TitleH4>
      <S.FiltersWrapper>
        <S.SelectWrapper>
          <BodyM $isUpper>Year</BodyM>
          <InputSelect
            isMulti
            options={yearsOptions}
            hideSelectedOptions={false}
            onChange={(values) => setSelectedYears(values as any)}
          />
        </S.SelectWrapper>
        <RadioGroup
          label='Gender'
          onChange={(e) => setGender(e.target.value)}
          options={[
            { label: 'Female', value: 'female' },
            { label: 'Male', value: 'male' },
            { label: 'Coed', value: 'coed' },
          ]}
        />
      </S.FiltersWrapper>
      <S.CardWrapper>
        {newAgeDivisions?.map((item) => (
          <S.EventCardDivision
            key={item.id}
            size='small'
            stripeColor={item.color}
            orientation='row'
            title={item.name as string}
            description={`${item.gender} ${item.years} ${
              item.years.length === 1 && 'Only'
            }`}
          />
        ))}
      </S.CardWrapper>
    </S.SectionWrapper>
  );
};
