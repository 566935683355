import { Modal } from 'antd';

import styled from 'styled-components';
import { COLORS } from 'styles/v3/variables';
import { BREAKPOINTS } from 'styles/v3/constants';
import { toRem } from 'styles/v3/functions';

interface RoleCard {
  isEnabled?: boolean;
}

export const StyledModal = styled(Modal)`
  .ant-modal-content {
    max-width: ${toRem(468)};
    border-radius: ${toRem(24)};
    background-color: ${COLORS.grey950};

    @media screen and (max-width: ${BREAKPOINTS.mobile}) {
      border-radius: 0;
      border-top-left-radius: ${toRem(24)};
      border-top-right-radius: ${toRem(24)};
    }
  }

  .ant-modal-header {
    border-radius: ${toRem(24)};
    background-color: ${COLORS.grey900};
  }

  .ant-tag {
    border-radius: ${toRem(24)};
    padding: ${toRem(6)} ${toRem(12)} ${toRem(6)} ${toRem(12)};
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  gap: ${toRem(32)};
`;

export const RolesWrapper = styled.div`
  display: flex;
  flex-direction: row;

  gap: ${toRem(32)};

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    align-items: center;
    flex-direction: column;

    gap: ${toRem(24)};
  }
`;

export const RoleCard =
  styled.div <
  RoleCard >
  `
  width: ${toRem(200)};
  height: ${toRem(200)};
  border-radius: ${toRem(24)};

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  background-color: ${({ isEnabled }) =>
    isEnabled ? COLORS.grey950 : COLORS.grey900};

  opacity: ${({ isEnabled }) => (isEnabled ? 1 : 0.4)};

  cursor: ${({ isEnabled }) => (isEnabled ? 'pointer' : 'not-allowed')};

  border: ${toRem(1)} solid
    ${({ isEnabled }) => (isEnabled ? COLORS.grey50 : COLORS.grey600)};

  p {
    text-transform: capitalize;
  }

  transition: transform 0.2s;

  ${({ isEnabled }) =>
    isEnabled &&
    `
    &:hover {
      transform: scale(1.1);
      color: ${COLORS.brandPrimary};
      background-color: rgba(202, 252, 1, 0.3);
      border: ${toRem(1)} solid ${COLORS.brandPrimary};
    }
  `}

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    width: 100%;
    height: ${toRem(150)};
  }
`;

export const CloseButton = styled.button`
  width: ${toRem(38)};
  height: ${toRem(38)};

  border: none;
  box-shadow: none;
  padding: ${toRem(7.4)} 0;
  border-radius: ${toRem(10)};
  background-color: ${COLORS.grey700};

  cursor: pointer;

  transition: all 0.3s;

  &:hover {
    background-color: ${COLORS.grey600};
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  h3 {
    margin: 0 !important;
  }
`;
