import React, { FunctionComponent, useState, useEffect, useMemo } from 'react';
import {
  useHistory,
  useLocation,
  useParams,
  withRouter,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { b2bSetupEventFetchEventRequest } from 'redux/v3/b2bSetupEvent/actions';
import { ApplicationState } from 'redux/store';
import ConvertUtil from 'util/ConvertUtil';
import B2bEventService from 'services/v3/B2bEvent/B2bEventService';
import { notification } from 'components/v3/Notification/notification';

// Icons
import { Calendar, LocalTwo, RightSmallUp, Close } from '@icon-park/react';

// Components
import TextButtonWithIcon from 'components/v3/Buttons/TextButtonWithIcon';
import InputCheckbox from 'components/v3/Forms/InputCheckbox/InputCheckbox';
import FilledButtonWithIcon from 'components/v3/Buttons/FilledButtonWithIcon';
import {
  TitleH1,
  TitleH4,
  BodyXL,
  BodyL,
  BodyM,
  BodyS,
} from 'styles/v3/variables';
import {
  PaymentAndValueProps,
  PaymentAndValue,
} from 'components/v3/Cards/PaymentAndValue';

// Types
import { RequestStatus } from 'models/Request/RequestModel';
import { useGetMyRefereeApplications } from 'hooks/v3/referees/useGetMyRefereeApplications/useGetMyRefereeApplications';
import { useGetEventInvites } from 'hooks/v3/event/useGetEventInvites/useGetEventInvites';
import {
  EventInvitesStatus,
  EventInvitesTypes,
} from 'models/v3/EventInvite/EventInvitesModel';
import { AllEventInvites } from 'services/v3/Event/types';
import EventService from 'services/v3/Event/EventService';
import { useQueryClient } from '@tanstack/react-query';

import ResponseUtil from 'util/ResponseUtil';
import { useProfile } from 'hooks/v3/profile/useProfile';
import { InformationScreenProps } from './InformationScreen';

// Styles
import {
  RefereeApplicationContainer,
  RefereeApplicationConditionsContainer,
  ContainerInformationCondition,
  EventInfoContainer,
  EventInformationAndImage,
  EventImage,
  GeneralInfoContainer,
  AgreeConditionsContainer,
  RefereeUploadSafeSportContainer,
  InvitesButtonsWrapper,
} from './styles';
import OutlinedButtonWithIcon from '../../../components/v3/Buttons/OutlinedButtonWithIcon';

const RefereeApplication: FunctionComponent = (param: any) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const queryClient = useQueryClient();

  const { updateCurrentAccount, currentUser } = useProfile();

  const history = useHistory();
  const params: { eventId: string } = useParams();
  const dispatch = useDispatch();
  const [acceptTerms, setAcceptTerms] = useState(false);

  const { data: myRefereeApplications } = useGetMyRefereeApplications(
    params.eventId
  );

  const invite = searchParams.get('invite') ?? '';

  const { data: eventInvites } = useGetEventInvites({
    id: params.eventId,
    type: EventInvitesTypes.EVENT_TO_REFEREE,
  });

  const lastApplications = useMemo(() => {
    if (!myRefereeApplications?.length) return undefined;

    const sorted = [...myRefereeApplications];

    sorted.sort((a, b) => {
      return (
        new Date(b.creationDate).getTime() - new Date(a.creationDate).getTime()
      );
    });

    return sorted[0];
  }, [myRefereeApplications]);

  const alreadyApplied = useMemo(() => {
    return (
      lastApplications &&
      ![RequestStatus.DECLINED, RequestStatus.EXPIRED].includes(
        lastApplications.status
      )
    );
  }, [lastApplications]);

  const myInvite = useMemo<AllEventInvites | undefined>(() => {
    if (!invite || !eventInvites?.length) return undefined;
    return eventInvites.find(
      (i) =>
        i?.invite?.status !== EventInvitesStatus.DENIED &&
        (currentUser.id === i.invite.receiverId ||
          currentUser?.userIds?.includes(i.invite.receiverId))
    );
  }, [invite, eventInvites, currentUser]);

  const hasSafesPortUploaded = useMemo(() => {
    return !!currentUser?.extraInfo?.certificate;
  }, [currentUser]);

  const { eventData } = useSelector(
    (state: ApplicationState) => state.b2bSetupEvent
  );

  const { logo, name, description, startDate, endDate, data } = eventData;

  const startAt = new Date(startDate as string).toLocaleDateString('en-US', {
    month: 'short',
    day: '2-digit',
    year: 'numeric',
  });

  const endAt = new Date(endDate as string).toLocaleDateString('en-US', {
    month: 'short',
    day: '2-digit',
    year: 'numeric',
  });

  const paymentsAndValues: PaymentAndValueProps[] = [
    {
      title: 'Single Referee',
      value: Number(data?.generalRules?.refereeConditions?.refereeSolo) ?? 0,
    },
    {
      title: 'Referee Pair',
      value: Number(data?.generalRules?.refereeConditions?.refereePair) ?? 0,
    },
    {
      title: 'Score Keeper',
      value: Number(data?.generalRules?.refereeConditions?.scoreKeeper) ?? 0,
    },
  ];

  const handleDenyInvite = () => {
    if (myInvite?.invite?.id) {
      EventService.denyInvite(myInvite.invite.id).then(() => {
        queryClient.invalidateQueries([
          'event-invites',
          params.eventId,
          EventInvitesTypes.EVENT_TO_REFEREE,
        ]);
      });
    }
  };

  const handleApplyToThisEvent = async () => {
    B2bEventService.postRefereeApplication(params.eventId)
      .then(() => {
        const stateToInformation: InformationScreenProps = {
          title: 'Your application is now pending.',
          description:
            'The event manager will review your application soon. With any updates, we will notify you.',
          eventIcon: logo ?? '',
          eventId: params.eventId,
        };
        updateCurrentAccount();
        history.push('/v3/referee-application-status', stateToInformation);
      })
      .catch((err) => {
        if (err) {
          const formattedErrors = ResponseUtil.formatInvalidRequestResponse(
            err
          );
          const invalidFields = formattedErrors.details.map((f) => f.field);
          const desc = invalidFields.length
            ? `Invalid fields: ${invalidFields.join(', ')}`
            : '';
          notification.error({
            message:
              'Please complete your profile before applying as a referee',
            description: desc,
          });
        } else {
          notification.error({
            message: 'Error applying as a referee, please try again',
          });
        }
      });
  };

  const handleUploadSafeSport = () => {
    history.push(`/v3/user/${currentUser.id}/documents?type=referee`);
  };

  useEffect(() => {
    dispatch(b2bSetupEventFetchEventRequest(params.eventId));
  }, [dispatch]);

  useEffect(() => {
    if (
      !data?.generalRules?.refereeConditions?.allowRefereesToSignUp &&
      !myInvite
    ) {
      history.push('/v3/events');
    }
  }, [data?.generalRules?.refereeConditions?.allowRefereesToSignUp, myInvite]);

  return (
    <RefereeApplicationContainer>
      <RefereeApplicationConditionsContainer>
        <TextButtonWithIcon
          color='light'
          align='flex-end'
          reverse
          icon='back'
          onClick={() => history.push('/v3/events')}
        >
          Go back
        </TextButtonWithIcon>
        <TitleH1 $color='brandPrimary'>Conditions</TitleH1>

        <ContainerInformationCondition>
          <BodyXL>Pay per Game</BodyXL>

          <BodyM $color='grey400'>
            Every payment will be done by the Event Manager.
          </BodyM>
          {paymentsAndValues.map((payment) => (
            <PaymentAndValue
              key={`key_payment_${payment.title}`}
              title={payment.title}
              value={payment.value}
            />
          ))}

          {data?.generalRules?.refereeConditions?.requirements && (
            <>
              <BodyXL>Requirements</BodyXL>
              <BodyM $color='grey400'>
                {data?.generalRules?.refereeConditions?.requirements}
              </BodyM>
            </>
          )}

          {data?.generalRules?.refereeConditions?.travelConditions && (
            <>
              <BodyXL>Travel Conditions</BodyXL>
              <BodyM $color='grey400'>
                {data?.generalRules?.refereeConditions?.travelConditions}
              </BodyM>
            </>
          )}
        </ContainerInformationCondition>
      </RefereeApplicationConditionsContainer>

      <EventInfoContainer>
        <EventInformationAndImage>
          <EventImage>
            <img src={ConvertUtil.getMinioUrl(logo)} alt='Event Logo' />
          </EventImage>
          <TitleH4>{name}</TitleH4>
          <BodyM $color='grey200'>LOCAL</BodyM>
          <BodyS $color='grey300'>{description}</BodyS>
        </EventInformationAndImage>
        <GeneralInfoContainer>
          <BodyL $isUpper>General Info</BodyL>
          <BodyM $isUpper style={{ display: 'flex', alignItems: 'center' }}>
            <Calendar /> {startAt} - {endAt}
          </BodyM>
          <BodyM $isUpper style={{ display: 'flex', alignItems: 'center' }}>
            <LocalTwo /> Local do Event
          </BodyM>
        </GeneralInfoContainer>

        {alreadyApplied ? (
          <BodyL $color='brandPrimary'>
            You already applied to this event.
          </BodyL>
        ) : (
          <>
            <AgreeConditionsContainer>
              <InputCheckbox
                id='accept_terms'
                name='accept_terms'
                checked={acceptTerms}
                onChange={() => setAcceptTerms(!acceptTerms)}
              />
              <BodyM $color='grey100'>
                I agree with the conditions provided for being a referee in this
                event.
              </BodyM>
            </AgreeConditionsContainer>

            {myInvite ? (
              <FilledButtonWithIcon
                disabled={!acceptTerms || !hasSafesPortUploaded}
                color={!acceptTerms ? 'white-dark' : 'primary'}
                isUpper
                onClick={handleApplyToThisEvent}
              >
                Apply as Referee
              </FilledButtonWithIcon>
            ) : (
              <InvitesButtonsWrapper>
                <FilledButtonWithIcon
                  isUpper
                  onClick={handleApplyToThisEvent}
                  color={!acceptTerms ? 'white-dark' : 'primary'}
                  disabled={!acceptTerms || !hasSafesPortUploaded}
                >
                  ACCEPT INVITE
                </FilledButtonWithIcon>
                <FilledButtonWithIcon
                  customIcon={<Close />}
                  className='btn-deny-invite'
                  color='dark-white'
                  isUpper
                  onClick={handleDenyInvite}
                >
                  DENY INVITE
                </FilledButtonWithIcon>
              </InvitesButtonsWrapper>
            )}
          </>
        )}

        {!hasSafesPortUploaded && (
          <RefereeUploadSafeSportContainer>
            <BodyL $color='supportError'>
              To apply as a Referee, you need to upload your SafeSport.
            </BodyL>
            <OutlinedButtonWithIcon
              customIcon={<RightSmallUp />}
              color='white-dark'
              isUpper
              onClick={handleUploadSafeSport}
            >
              Upload Safesport
            </OutlinedButtonWithIcon>
          </RefereeUploadSafeSportContainer>
        )}
      </EventInfoContainer>
    </RefereeApplicationContainer>
  );
};

export default withRouter(RefereeApplication);
