import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import {
  b2bDraftEventFetchProductRequest,
  b2bDraftEventSetEventType,
} from 'redux/v3/b2bDraftEvent/actions';
import { useEffect } from 'react';
import { TitleH1, BodyM, BodyMBold } from 'styles/v3/variables';

import FilledButtonWithIcon from 'components/v3/Buttons/FilledButtonWithIcon';
import PlanCard from 'components/v3/Cards/PlanCard/PlanCard';
import Loading from 'components/v3/Loading/Loading';
import { ApplicationState } from '../../../redux/store';

import * as S from './styles';
import { PlanListProps } from './types';

const PlanSelection: React.FunctionComponent = (props) => {
  const history = useHistory();

  const dispatch = useDispatch();

  const b2bDraftEvent = useSelector(
    (state: ApplicationState) => state.b2bDraftEvent
  );

  const {
    loading,
    data: { events, selectedEvent },
  } = b2bDraftEvent;

  function handleNextStep() {
    if (!selectedEvent) {
      alert('Please select an plan');
      return;
    }
    history.push('/v3/draft-event/general-event');
  }

  const handleSelectEvent = (eventId: string) => {
    dispatch(b2bDraftEventSetEventType(eventId));
  };

  const sharedPlanList = [
    {
      text: 'Club and teams Registration',
      highlight: false,
    },
    {
      text: 'Bracket Management',
      highlight: false,
    },
    {
      text: 'Schedule Management',
      highlight: false,
    },
    {
      text: 'Game Results Tracking',
      highlight: false,
    },
    {
      text: 'Access to Referees database',
      highlight: false,
    },
    {
      text: 'Current VAULT members play free',
      highlight: false,
    },
    {
      text: 'Player registration and verification',
      highlight: false,
    },
    {
      text: 'Event Publish in public VAULT calendar',
      highlight: false,
    },
    {
      text: 'Communication',
      highlight: false,
    },
  ];

  const highlightedPlanList = [
    {
      text: 'Qualification pathway for World Futsal Championships for teams',
      highlight: true,
    },
    {
      text: 'Sanctioned by United Futsal to US Soccer',
      highlight: true,
    },
  ];

  const planList: PlanListProps = {
    B2B_EVENT_LEAGUE: [
      {
        text: 'League Management',
        highlight: false,
      },
      ...[...sharedPlanList],
      {
        text: 'Players get XP (access multiplier system)',
        highlight: false,
      },
      ...[...highlightedPlanList],
    ],
    B2B_EVENT_TOURNAMENT: [
      {
        text: 'Tournament Management',
        highlight: false,
      },
      ...[...sharedPlanList],
      ...[...highlightedPlanList],
    ],
  };

  useEffect(() => {
    dispatch(b2bDraftEventFetchProductRequest());
  }, [dispatch]);

  if (loading) {
    return <Loading />;
  }

  if (!events || events.length === 0 || !Array.isArray(events)) {
    return <h1>No events found... Please Reload the page</h1>;
  }

  if (events === undefined) {
    return <Loading />;
  }

  return (
    <S.PlanSelectionWrapper>
      <S.PlanSelectionText>
        <TitleH1 $spanColor='brandPrimary'>
          No Surprises.
          <br />
          <span>Discover now How much your event will cost.</span>
        </TitleH1>
        <BodyM $color='grey200'>
          Create your futsal event using all the amazing features of Vault.
          Choose between tournament or league, and we&apos;ll tell you the final
          cost.
          <br />
          <BodyMBold $color='white'>
            Fast and skillful like on the courts.
          </BodyMBold>
        </BodyM>
      </S.PlanSelectionText>

      <S.PlanSelectionContainer>
        {events.map((event) => (
          <PlanCard
            key={event.id}
            id={event.id}
            size='large'
            theme={event.type === 'B2B_EVENT_LEAGUE' ? 'secondary' : 'primary'}
            name='plans'
            selected={selectedEvent?.id}
            onClick={() => handleSelectEvent(event.id)}
            onMouseEnter={() => handleSelectEvent(event.id)}
            title={event.title}
            price='$500'
            orientation='column'
            description={
              event.type === 'B2B_EVENT_LEAGUE'
                ? 'Recurrent event with up to 12 weeks'
                : 'Short event with maximum 7 days'
            }
            details={planList[event.type]}
          >
            <FilledButtonWithIcon
              onClick={handleNextStep}
              color={
                event.type === 'B2B_EVENT_LEAGUE'
                  ? 'dark-white'
                  : 'dark-primary'
              }
              isUpper
            >
              {event.type === 'B2B_EVENT_LEAGUE'
                ? 'Continue League'
                : 'Continue Tournament'}
            </FilledButtonWithIcon>
          </PlanCard>
        ))}
      </S.PlanSelectionContainer>
    </S.PlanSelectionWrapper>
  );
};

export default withRouter(PlanSelection);
