import React from 'react';
import { NumericFormat, NumericFormatProps } from 'react-number-format';
import InputText, { InputTextProps } from '../InputText/InputText';

interface InputFormatProps
  extends Omit<NumericFormatProps, 'defaultValue' | 'id'>,
    Omit<
      InputTextProps,
      'max' | 'min' | 'style' | 'value' | 'type' | 'onBlur' | 'onFocus'
    > {
  id: string;
  customInput?: React.ComponentType<any>;
}

export const InputFormat = ({
  id,
  prefix,
  suffix,
  ...rest
}: InputFormatProps) => {
  return (
    <NumericFormat
      id={id}
      suffix={suffix}
      decimalScale={2}
      thousandSeparator
      prefix={prefix ?? '$ '}
      customInput={InputText}
      {...rest}
    />
  );
};
