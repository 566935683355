import styled from 'styled-components';
import { toRem } from 'styles/v3/functions';
import { COLORS } from 'styles/v3/variables';

export const Container = styled.div`
  display: flex;
  /* max-width: ${toRem(996)}; */
  /* width: 100%; */
  padding: ${toRem(24)};

  margin-right: ${toRem(228.6)};

  justify-content: space-between;

  border-radius: ${toRem(24)};
  border: 1px solid ${COLORS.brandSecondary};
  background: rgba(151, 71, 255, 0.1);

  margin-bottom: ${toRem(24)};

  .btn-complete {
    margin-top: auto;
  }
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${toRem(16)};
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: ${toRem(8)};

  .i-icon svg {
    width: ${toRem(32)};
    height: ${toRem(32)};
    display: flex;
    &::before {
      display: none;
    }
    path {
      stroke: ${COLORS.brandSecondary};
    }
    path[fill-rule='evenodd'] {
      fill: ${COLORS.brandSecondary};
    }
  }
`;

export const ToolTipContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${toRem(5)};
`;
