import * as React from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { Switch, message as MessageSnackbar } from 'antd';
import { Country, State } from 'country-state-city';

import InputText from 'components/v3/Forms/InputText/InputText';
import InputTextLimit from 'components/v3/Forms/InputTextLimit/InputTextLimit';
import TextButtonWithIcon from 'components/v3/Buttons/TextButtonWithIcon';
import Select, { OptionsType } from 'components/v3/Forms/Select/Select';
import InputFile from 'components/v3/Forms/InputFile/InputFile';
import FilledButtonWithIcon from 'components/v3/Buttons/FilledButtonWithIcon';
import { notification } from 'components/v3/Notification/notification';

import UploadService from 'services/upload/UploadService';
import ClubService from 'services/v3/Clubs/ClubService'

import { ClubCreation } from 'admin/models/club/Club';


import {
  BodyLBold,
  BodyM,
  BodyMBold,
  BodyS,
  BodyXL,
  TitleH2,
} from 'styles/v3/variables';

import ResponseUtil from 'util/ResponseUtil';
import { useProfile } from 'hooks/v3/profile/useProfile';
import * as S from './styles';

type Fields = {
  name: { value: string; error: boolean; required: boolean };
  displayName: { value: string; error: boolean; required: boolean };
  description: { value: string; error: boolean; required: boolean };
  allowApplications: { value: boolean; error: boolean; required: boolean };
  country: { value: string; error: boolean; required: boolean };
  state: { value: string; error: boolean; required: boolean };
  city: { value: string; error: boolean; required: boolean };
  instagram: { value: string; error: boolean; required: boolean };
  tiktok: { value: string; error: boolean; required: boolean };
  x: { value: string; error: boolean; required: boolean };
  facebook: { value: string; error: boolean; required: boolean };
  logo: { value: string; error: boolean; required: boolean };
  header: { value: string; error: boolean; required: boolean };
};

const NewClub = () => {
  const formRef = React.useRef<HTMLFormElement>(null);
  const logoRef = React.useRef<HTMLInputElement>(null);
  const headerRef = React.useRef<HTMLInputElement>(null);
  const history = useHistory();

  const { updateCurrentAccount, currentUser } = useProfile()

  function handlePreviousStep() {
    history.goBack();
  }

  const stateFields = {
    name: { value: '', error: false, required: true },
    displayName: { value: '', error: false, required: true },
    description: { value: '', error: false, required: false },
    allowApplications: { value: false, error: false, required: false },
    country: { value: '', error: false, required: true },
    state: { value: '', error: false, required: true },
    city: { value: '', error: false, required: true },
    instagram: { value: '', error: false, required: false },
    tiktok: { value: '', error: false, required: false },
    x: { value: '', error: false, required: false },
    facebook: { value: '', error: false, required: false },
    logo: { value: '', error: false, required: true },
    header: { value: '', error: false, required: false },
  };

  const [fields, setFields] = React.useState<Fields>(stateFields);
  const [countryOptions, setCountryOptions] = React.useState<OptionsType[]>([]);
  const [statesOptions, setStatesOptions] = React.useState<OptionsType[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);

  const setLabelToState = statesOptions.find(
    (s) => s.value === stateFields.state.value
  );

  const handleChangeSelect = async (field: keyof Fields, e: any) => {
    setFields({
      ...fields,
      [field]: {
        ...fields[field],
        value: e.value,
        error: false,
      },
    });
  };

  const handleInputClear = (field: keyof Fields) => {
    setFields({
      ...fields,
      [field]: { ...fields[field], value: '' },
    });
  };

  const handleChangeInputValue = (
    e: React.ChangeEvent<HTMLInputElement>,
    field: keyof Fields
  ) => {
    const { name, value } = e.target;

    setFields({
      ...fields,
      [name]: {
        required: stateFields[field]?.required ?? true,
        error: false,
        value,
      },
    });
  };

  const handleImageUpload = async (file: File, field: string) => {
    let fileId = '';
    if (file) {
      setLoading(true);
      try {
        await UploadService.upload(
          {
            file,
            prefix: 'club',
            public: true,
          },
          true
        ).then((response) => {
          if (response.success) {
            fileId = response.data.id;
          }
        });
      } catch (e) {
        setLoading(false);
        if (e instanceof Error) {
          MessageSnackbar.error({ content: e.message, duration: 5 });
        }
      }
    }
    return fileId;
  };

  const validate = () => {
    setLoading(true);
    for (const [key, values] of Object.entries(fields)) {
      if (values?.value === '' && values?.required) {
        setFields((newFields) => ({
          ...newFields,
          [key]: { value: values.value, error: true },
        }));
      }
    }
  };

  const saveClub = async () => {
    let logoPath = '';
    let headerPath = '';
    try {
      if (logoRef.current && logoRef.current.files?.length) {
        logoPath = await handleImageUpload(logoRef.current.files[0], 'logo');
      }
      if (headerRef.current && headerRef.current.files?.length) {
        headerPath = await handleImageUpload(
          headerRef.current.files[0],
          'header'
        );
      }

      if (formRef.current) {
        const formData: FormData = new FormData(formRef.current);
        const payload: ClubCreation = {
          name: formData.get('name') as string,
          displayName: formData.get('displayName') as string,
          description: formData.get('description') as string,
          allowApplications: fields.allowApplications.value,
          country: formData.get('country') as string,
          state: formData.get('state') as string,
          city: formData.get('city') as string,
          socialMedias: {
            instagram: formData.get('instagram') as string,
            tiktok: formData.get('tiktok') as string,
            x: formData.get('x') as string,
            facebook: formData.get('facebook') as string,
          },
          logo: logoPath,
          header: headerPath,
          directorIds: [currentUser.id ?? ''],
        };
        ClubService.createWithDirector(payload).then(res => {
          updateCurrentAccount()
          history.push('/v3/clubs');
        }).catch(err => {
          if (err) {
            const formattedErrors = ResponseUtil.formatInvalidRequestResponse(err)
            const invalidFields = formattedErrors.details.map(f => f.field)
            const description = invalidFields.length ? `Invalid fields: ${invalidFields.join(', ')}` : ''
            notification.error({
              message: 'Please complete your profile before creating a club',
              description
            });
          }
        }).finally(() => {
          setLoading(false);
        })

      }
    } catch (e) {
      setLoading(false);
      if (e instanceof Error) {
        MessageSnackbar.error({ content: e.message, duration: 5 });
      }
    }
  };

  React.useEffect(() => {
    const allCountries = Country.getAllCountries().map((i) => ({
      label: `${i.name} ${i.flag}`,
      value: i.isoCode,
    }));
    setCountryOptions(allCountries);
  }, []);

  React.useEffect(() => {
    const countryCode = fields.country.value;
    if (countryCode) {
      const statesOfCountry = State.getStatesOfCountry(countryCode).map(
        (i) => ({
          label: i.name,
          value: i.isoCode,
        })
      );
      setStatesOptions(statesOfCountry);
    }
  }, [fields.country.value]);

  React.useEffect(() => {
    if (loading) {
      const getFields = Object.values(fields);
      const hasError = getFields.find((i) => i?.error);

      if (!hasError) {
        saveClub();
      } else {
        setLoading(false);
      }
    }
  }, [loading, fields]);

  return (
    <>
      <S.PageTitle $maxWidth={636}>
        <TextButtonWithIcon
          color='light'
          align='flex-end'
          reverse
          icon='back'
          onClick={handlePreviousStep}
        >
          Go back
        </TextButtonWithIcon>
        <TitleH2 $isUpper>Create New Club</TitleH2>
      </S.PageTitle>

      <S.Wrapper $maxWidth={636}>
        <S.Form ref={formRef} onSubmit={(e) => e.preventDefault()}>
          <S.Fieldset>
            <InputText
              required
              id='name'
              placeholder='Club Name'
              label='Club Name'
              error={fields.name.error}
              onClearInput={handleInputClear}
              onChange={(e) => handleChangeInputValue(e, 'name')}
              className='input-field'
            />
            <InputTextLimit
              required
              id='displayName'
              placeholder='Display Name'
              label='Display Name'
              error={fields.displayName.error}
              limit={12}
              caption='This display name will be used in public settings, schedules and other screens.'
              onClearInput={handleInputClear}
              onChange={(e) => handleChangeInputValue(e, 'displayName')}
              className='input-field'
            />
            <InputTextLimit
              id='description'
              placeholder='Description of your Club'
              label='Description of your Club'
              limit={144}
              onClearInput={handleInputClear}
              onChange={(e) => handleChangeInputValue(e, 'description')}
              className='input-field'
            />
            <S.SwitchWrapper>
              <Switch
                onChange={(e) =>
                  setFields({
                    ...fields,
                    allowApplications: {
                      ...fields.allowApplications,
                      value: e,
                    },
                  })
                }
              />
              <div className='switch-text'>
                <BodyMBold className='switch-title'>
                  Allow applications
                </BodyMBold>
                <BodyS $color='grey400'>
                  This will allow any person on the platform to apply to be in
                  your club. You will be able to approve or deny a application.
                  You will also be able to change this later.
                </BodyS>
              </div>
            </S.SwitchWrapper>
          </S.Fieldset>

          <S.Fieldset>
            <legend>
              <BodyXL $color='brandPrimary' $isUpper>
                Location
              </BodyXL>
            </legend>

            <div className='half-inputs'>
              {countryOptions.length > 0 && (
                <Select
                  name='country'
                  placeholder='Country'
                  required
                  isSearchable
                  options={countryOptions}
                  onChange={(e) => handleChangeSelect('country', e)}
                  error={fields.country.error}
                  className='input-field'
                />
              )}
              {statesOptions.length > 0 && (
                <Select
                  name='state'
                  placeholder='State'
                  required
                  isSearchable
                  options={statesOptions}
                  defaultValue={setLabelToState}
                  onChange={(e) => handleChangeSelect('state', e)}
                  error={fields.state.error}
                  className='input-field'
                />
              )}
            </div>

            <InputText
              required
              id='city'
              placeholder='City'
              label='City'
              error={fields.city.error}
              onClearInput={handleInputClear}
              onChange={(e) => handleChangeInputValue(e, 'city')}
            />
          </S.Fieldset>

          <S.Fieldset>
            <legend>
              <BodyXL $color='brandPrimary' $isUpper>
                Social Media
              </BodyXL>
            </legend>

            <InputText
              id='instagram'
              placeholder='Instagram'
              label='Instagram'
              onClearInput={handleInputClear}
              onChange={(e) => handleChangeInputValue(e, 'instagram')}
              className='input-field'
            />
            <InputText
              id='tiktok'
              placeholder='TikTok'
              label='TikTok'
              onClearInput={handleInputClear}
              onChange={(e) => handleChangeInputValue(e, 'tiktok')}
              className='input-field'
            />
            <InputText
              id='x'
              placeholder='X (Formerly Twitter)'
              label='X (Formerly Twitter)'
              onClearInput={handleInputClear}
              onChange={(e) => handleChangeInputValue(e, 'x')}
              className='input-field'
            />
            <InputText
              id='facebook'
              placeholder='Facebook'
              label='Facebook'
              onClearInput={handleInputClear}
              onChange={(e) => handleChangeInputValue(e, 'facebook')}
            />
          </S.Fieldset>

          <S.Fieldset>
            <legend>
              <BodyXL $color='brandPrimary' $isUpper>
                Identity
              </BodyXL>
            </legend>

            <BodyLBold $color='white' $spanColor='supportError'>
              Logo <span>*</span>
            </BodyLBold>
            <BodyM $color='grey400' className='upload-file-text'>
              Upload a Logo for your club. The logo must be{' '}
              <BodyMBold>512px by 512px</BodyMBold>
            </BodyM>
            <InputFile
              required
              id='logo'
              text='Add logo'
              format='circle'
              className='input-field'
              error={fields.logo.error}
              ref={logoRef}
              onChange={(e) => handleChangeInputValue(e, 'logo')}
            />

            <BodyLBold $color='white'>
              Header <BodyS $color='grey400'>(Optional)</BodyS>
            </BodyLBold>
            <BodyM className='upload-file-text'>
              Upload a Header for your club. The header will show up on your
              club page. It must be <BodyMBold>1440px by 250px</BodyMBold>
            </BodyM>
            <InputFile
              id='header'
              text='Add logo'
              format='square'
              error={fields.header.error}
              ref={headerRef}
            />
          </S.Fieldset>

          <S.Fieldset className='button-wrapper'>
            <FilledButtonWithIcon isUpper onClick={validate} disabled={loading}>
              Send to Approval
            </FilledButtonWithIcon>
          </S.Fieldset>
        </S.Form>
      </S.Wrapper>
    </>
  );
};

export default withRouter(NewClub);
