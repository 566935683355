import * as React from 'react';
import { withRouter, useHistory, useParams } from 'react-router-dom';
import { message as MessageSnackbar } from 'antd';
import moment from 'moment';

import { BodyXL } from 'styles/v3/variables';

import InputText from 'components/v3/Forms/InputText/InputText';
import InputPhone from 'components/v3/Forms/InputPhone/InputPhone';
import Select, { OptionsType } from 'components/v3/Forms/Select/Select';
import InputDate from 'components/v3/Forms/InputDate/InputDate';
import FilledButtonWithIcon from 'components/v3/Buttons/FilledButtonWithIcon';

import { PatchBaseUser } from 'redux/v3/baseUsers/types';
import UserServices from 'services/v3/User/UserService';
import { RequestStatus } from 'models/Request/RequestModel';

import { useProfile } from 'hooks/v3/profile/useProfile';
import { Gender } from 'admin/models/Enums';

import CompleteUserTemplate from '../Template/Template';
import * as S from '../Template/styles';

type Fields = {
  firstName: { value: string; error: boolean; required: boolean };
  middleName: { value: string; error: boolean; required: boolean };
  lastName: { value: string; error: boolean; required: boolean };
  suffix: { value: string; error: boolean; required: boolean };
  selectEmail: { value: string; error: boolean; required: boolean };
  selectPhone: { value: string; error: boolean; required: boolean };
  birthDate: {
    value: string | moment.Moment;
    error: boolean;
    required: boolean;
  };
  gender: { value: string; error: boolean; required: boolean };
  newEmail?: { value: string; error: boolean; required: boolean };
  newPhone?: { value: string; error: boolean; required: boolean };
};

const UserInfo = () => {
  const history = useHistory();
  const formRef = React.useRef<HTMLFormElement>(null);
  const params: { userId: string } = useParams();

  const {
    currentUser,
    isBaseUser,
    isAnAdult,
    updateCurrentAccount,
  } = useProfile();

  const userInfoIsDisabled = !!(
    currentUser.firstName && currentUser.ageVerified === RequestStatus.PENDING
  );
  const fieldsInfo = 'This fields is not be able to update';
  const userType = currentUser.type ?? '';

  const [emailUser, setEmailUser] = React.useState<OptionsType>();

  const stateFields: Fields = React.useMemo(() => {
    return {
      firstName: {
        value: currentUser?.firstName ?? '',
        error: false,
        required: true,
      },
      middleName: {
        value: currentUser?.middleName ?? '',
        error: false,
        required: false,
      },
      lastName: {
        value: currentUser?.lastName ?? '',
        error: false,
        required: true,
      },
      suffix: {
        value: currentUser?.suffix ?? '',
        error: false,
        required: false,
      },
      selectEmail: {
        value: currentUser?.email ?? currentUser?.accountEmail ?? '',
        error: false,
        required: true,
      },
      selectPhone: {
        value: currentUser?.phoneNumber ?? '',
        error: false,
        required: true,
      },
      birthDate: {
        value: currentUser?.birthDate ? (currentUser?.birthDate as any) : '',
        error: false,
        required: true,
      },
      gender: {
        value: currentUser?.gender ?? '',
        error: false,
        required: true,
      },
    };
  }, [currentUser]);

  const emailOptions: OptionsType[] = [
    {
      label: stateFields.selectEmail.value,
      value: stateFields.selectEmail.value,
    },
    {
      label: 'New Email',
      value: 'new-email',
    },
  ];
  const phoneOptions: OptionsType[] = [
    {
      label: stateFields.selectPhone.value,
      value: stateFields.selectPhone.value,
    },
    {
      label: 'New Phone',
      value: 'new-phone',
    },
  ];
  const genderOptions: OptionsType[] = [
    {
      label: 'Male',
      value: Gender.MALE,
    },
    {
      label: 'Female',
      value: Gender.FEMALE,
    },
  ];

  const [fields, setFields] = React.useState<Fields>(stateFields);
  const [validating, setValidating] = React.useState<boolean>(false);
  const [isNewEmail, setIsNewEmail] = React.useState<boolean>(false);
  const [isNewPhone, setIsNewPhone] = React.useState<boolean>(false);

  const setLabelToEmail = emailOptions.find(
    (e) => e.value === stateFields.selectEmail.value
  );
  const setLabelToPhone = phoneOptions.find(
    (p) => p.value === stateFields.selectPhone.value
  );
  const setLabelToGender = genderOptions.find(
    (g) => g.value === stateFields.gender.value
  );

  React.useEffect(() => {
    if (currentUser) {
      setEmailUser({
        value: `${currentUser?.accountEmail}`,
        label: `${currentUser?.accountEmail}`,
      });
    }
  }, [currentUser]);

  const handleChangeSelect = (field: keyof Fields, e: any) => {
    setFields({
      ...fields,
      [field]: {
        ...fields[field],
        value: e.value,
        error: false,
      },
    });
    setValidating(false);
  };

  const handleInputClear = (field: keyof Fields) => {
    if (field === 'newEmail') {
      setIsNewEmail(false);
    }
    setFields({
      ...fields,
      [field]: { ...fields[field], value: '' },
    });
    setValidating(false);
  };

  const handleChangeInputValue = (
    e: React.ChangeEvent<HTMLInputElement>,
    field: keyof Fields
  ) => {
      const { name, value } = e.target;

      if (name === 'newEmail' || name === 'newPhone') {
        if (value === '') {
          if (name === 'newEmail') {
            setIsNewEmail(false);
          } else {
            setIsNewPhone(false);
          }
        }
      }
    setFields({
      ...fields,
      [name]: {
        required: stateFields[field]?.required ?? true,
        error: false,
        value,
      },
    });
    setValidating(false);
  };

  const handleGoToNextStep = () => {
    if (isAnAdult) {
      history.push(`/v3/user/${params.userId}/address`);
    } else {
      history.push(`/v3/user/${params.userId}/legal-guardian`);
    }
  };

  const validate = () => {
    setValidating(true);
    if (userInfoIsDisabled && !isNewEmail && !isNewPhone) handleGoToNextStep();

    for (const [key, values] of Object.entries(fields)) {
      if (values?.value === '' && values?.required) {
        setFields((newFields) => ({
          ...newFields,
          [key]: { value: values.value, error: true },
        }));
      }
    }
    const firstNameError =
      fields.firstName.value.length < 2 || fields.firstName.value.length > 200;
    const lastNameError =
      fields.lastName.value.length < 2 || fields.lastName.value.length > 200;
    setFields((prev) => ({
      ...prev,
      firstName: { ...prev.firstName, error: firstNameError },
      lastName: { ...prev.lastName, error: lastNameError },
    }));
  };

  const saveUser = async () => {
    if (formRef.current) {
      const formData: FormData = new FormData(formRef.current);

      const payload: PatchBaseUser = {
        firstName: (formData.get('firstName') ??
          fields.firstName.value) as string,
        middleName: formData.get('middleName') as string,
        lastName: (formData.get('lastName') ?? fields.lastName.value) as string,
        suffix: formData.get('suffix') as string,
        email: (isNewEmail
          ? formData.get('newEmail')
          : formData.get('selectEmail')) as string,
        phoneNumber: (isNewPhone
          ? formData.get('newPhone')
          : formData.get('selectPhone')) as string,
        birthDate: (formData.get('birthDate') ??
          fields.birthDate.value) as string,
        gender: (formData.get('gender') !== null
          ? String(formData.get('gender'))
          : fields.gender.value) as Gender,
      };

      await UserServices.updateUser(
        `${currentUser.id}`,
        payload,
        isBaseUser,
        userType
      )
        .then(async () => {
          updateCurrentAccount();
          handleGoToNextStep();
        })
        .catch((error) => {
          setValidating(false);
          if (error instanceof Error) {
            MessageSnackbar.error({ content: error.message, duration: 5 });
          }
        });
    }
  };

  React.useEffect(() => {
    if (validating) {
      const getFields = Object.values(fields);
      const hasError = getFields.find((i) => i?.error);

      if (!hasError) {
        saveUser();
      } else {
        setValidating(false);
      }
    }
  }, [validating, fields]);

  React.useEffect(() => {
    if (fields.selectEmail.value === 'new-email') {
      setIsNewEmail(true);
    } else {
      setIsNewEmail(false);
    }
  }, [fields.selectEmail.value]);
  
  React.useEffect(() => {
    if (fields.selectPhone.value === 'new-phone' || !currentUser?.phoneNumber) {
      setIsNewPhone(true);
    } else {
      setIsNewPhone(false);
    }
  }, [fields.selectPhone.value, currentUser?.phoneNumber]);

  React.useEffect(() => {
    if (isNewEmail) {
      setFields({
        ...fields,
        newEmail: { value: '', error: false, required: true },
        selectEmail: { ...fields.selectEmail, required: false },
      });
    } else {
      setFields({
        ...fields,
        newEmail: { value: '', error: false, required: false },
        selectEmail: {
          ...fields.selectEmail,
          value: stateFields.selectEmail.value,
          required: true,
        },
      });
    }
  }, [isNewEmail]);

  React.useEffect(() => {
    if (isNewPhone) {
      setFields({
        ...fields,
        newPhone: { value: '', error: false, required: true },
        selectPhone: { ...fields.selectPhone, required: false },
      });
    } else {
      setFields({
        ...fields,
        newPhone: { value: '', error: false, required: false },
        selectPhone: {
          ...fields.selectPhone,
          value: stateFields.selectPhone.value,
          required: true,
        },
      });
    }
  }, [isNewPhone]);

  const valueButton = () => {
    if (validating) {
      return 'Saving';
    }

    if (currentUser) {
      return 'Update';
    }

    return 'Continue';
  };

  return (
    <CompleteUserTemplate user={currentUser as any}>
      <S.UserForm ref={formRef} onSubmit={(e) => e.preventDefault()}>
        <fieldset>
          <legend>
            <BodyXL $color='brandPrimary' $isUpper>
              User Info {currentUser && 'Edit'}
            </BodyXL>
          </legend>

          <InputText
            id='firstName'
            placeholder='First Name'
            label='First Name'
            required
            errorMessage='The first name must be between 2 and 200 characters'
            disabled={userInfoIsDisabled}
            info={fieldsInfo}
            key={`firstName_${currentUser?.id}`}
            defaultValue={stateFields.firstName.value}
            onClearInput={(id) => handleInputClear(id)}
            error={fields.firstName.error}
            onChange={(e) => handleChangeInputValue(e, 'firstName')}
            containerClassName='user-info-input'
          />
          <InputText
            id='middleName'
            placeholder='Middle Name (Optional)'
            label='Middle Name (Optional)'
            disabled={userInfoIsDisabled}
            info={fieldsInfo}
            key={`middleName_${currentUser?.id}`}
            defaultValue={stateFields.middleName.value}
            onClearInput={(id) => handleInputClear(id)}
            error={fields.middleName.error}
            onChange={(e) => handleChangeInputValue(e, 'middleName')}
            containerClassName='user-info-input'
          />
          <InputText
            id='lastName'
            placeholder='Last Name'
            errorMessage='The last name must be between 2 and 200 characters'
            label='Last Name'
            required
            disabled={userInfoIsDisabled}
            info={fieldsInfo}
            key={`lastName_${currentUser?.id}`}
            defaultValue={stateFields.lastName.value}
            onClearInput={(id) => handleInputClear(id)}
            error={fields.lastName.error}
            onChange={(e) => handleChangeInputValue(e, 'lastName')}
            containerClassName='user-info-input'
          />
          <InputText
            id='suffix'
            placeholder='Suffix (Optional)'
            label='Suffix (Optional)'
            disabled={userInfoIsDisabled}
            info={fieldsInfo}
            key={`suffix_${currentUser?.id}`}
            defaultValue={stateFields.suffix.value}
            onClearInput={(id) => handleInputClear(id)}
            onChange={(e) => handleChangeInputValue(e, 'suffix')}
            containerClassName='user-info-input'
          />
        </fieldset>

        <S.FormFieldset>
          {isNewEmail ? (
            <InputText
              id='newEmail'
              placeholder='New Email'
              label='New Email'
              required
              onClearInput={(id) => handleInputClear(id)}
              error={fields.newEmail?.error}
              onChange={(e) => handleChangeInputValue(e, 'newEmail')}
              containerClassName='user-info-input'
            />
          ) : (
            <Select
              name='selectEmail'
              placeholder='Select Email'
              required
              options={emailOptions}
              key={`selectEmail_${currentUser?.id}`}
              defaultValue={emailUser || setLabelToEmail}
              onChange={(e) => handleChangeSelect('selectEmail', e)}
              error={fields.selectEmail.error}
              className='user-info-input'
            />
          )}
          {isNewPhone ? (
            <InputPhone
              id='newPhone'
              placeholder='+1 (___) ___-____'
              label='New Phone'
              required
              error={fields.newPhone?.error}
              onChange={(e) => handleChangeInputValue(e, 'newPhone')}
              containerClassName='user-info-input'
            />
          ) : (
            <Select
              name='selectPhone'
              placeholder='Select Telephone'
              required
              options={phoneOptions}
              key={`selectPhone_${currentUser?.id}`}
              defaultValue={setLabelToPhone}
              onChange={(e) => handleChangeSelect('selectPhone', e)}
              error={fields.selectPhone.error}
              className='user-info-input'
            />
          )}
        </S.FormFieldset>

        <S.FormFieldset>
          <InputDate
            id='birthDate'
            placeholder='Birthdate'
            label='Birthdate'
            disabled={userInfoIsDisabled}
            info={fieldsInfo}
            key={`birthDate_${currentUser?.id}`}
            defaultValue={stateFields.birthDate.value as string}
            onChange={(e) => handleChangeInputValue(e, 'birthDate')}
            required
            error={fields.birthDate.error}
            containerClassName='user-info-input'
          />
          <Select
            name='gender'
            placeholder='Gender'
            required
            isDisabled={userInfoIsDisabled}
            info={fieldsInfo}
            error={fields.gender.error}
            options={genderOptions}
            defaultValue={setLabelToGender}
            onChange={(e) => handleChangeSelect('gender', e)}
            className='user-info-input'
          />
        </S.FormFieldset>

        <FilledButtonWithIcon onClick={validate} isUpper>
          {valueButton()}
        </FilledButtonWithIcon>
      </S.UserForm>
    </CompleteUserTemplate>
  );
};

export default withRouter(UserInfo);
