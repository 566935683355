import React, { useMemo } from 'react';
import { Camera, IdCardH, IdCardV } from '@icon-park/react';
import { Icon } from '@icon-park/react/es/all';
import { BodyS, COLORS } from 'styles/v3/variables';
import { ItemProps } from './types';
import * as S from './styles';

export const Item = ({ name, status, permission }: ItemProps) => {
  const isEnabled = useMemo(() => {
    const safeSportPermission = ['COACH', 'CLUB_DIRECTOR', 'REFEREE'];
    const agePermission = ['PLAYER'];
    switch (name) {
      case 'SS':
        return safeSportPermission.includes(permission);
      case 'AV':
        return agePermission.includes(permission);
      default:
        return true;
    }
  }, [name, permission]);

  const CurrentIcon: Icon = useMemo(() => {
    switch (name) {
      case 'SS':
        return IdCardV;
      case 'AV':
        return IdCardH;
      default:
        return Camera;
    }
  }, [name]);

  const isApproved = useMemo(() => {
    return status === 'APPROVED' || status === 'PENDING' || !isEnabled;
  }, [status]);

  const title = useMemo(() => {
    const notApply = !isEnabled ? '(Not Apply)' : '';
    switch (name) {
      case 'SS':
        return `SafeSport Status ${notApply}`;
      case 'AV':
        return `Age Proof Status ${notApply}`;
      default:
        return `Photo Status ${notApply}`;
    }
  }, [name]);

  return (
    <S.Container title={title} aria-label={title} $enabled={isEnabled}>
      <S.Icon>
        <CurrentIcon
          size={24}
          className='reset'
          color={isApproved ? COLORS.brandPrimary : COLORS.supportError}
        />
      </S.Icon>
      <BodyS>{name}</BodyS>
    </S.Container>
  );
};
