import React, { useMemo, useState } from 'react';

import { BodyM, BodyMBold } from 'styles/v3/variables';

import { Up, Down, Plus } from '@icon-park/react';

import OutlinedButton from 'components/v3/Buttons/OutlinedButton';

import { ApplicationState } from 'redux/store';
import { useDispatch, useSelector } from 'react-redux';
import UserType from 'models/User/UserTypeModel';
import { useHistory } from 'react-router-dom';
import { onClose } from 'redux/v3/profileModal/actions';
import { AccountCard } from './components/AccountCard/AccountCard';
import { AccountsAvatarGroup } from './components/AccountsAvatarGroup/AccountsAvatarGroup';

import * as S from './styles';

export const AccountAccordion = () => {
  const history = useHistory();
  const dispatch = useDispatch()

  const [showAvatarGroup, setShowAvatarGroup] = useState(true);

  const currentUserId = useSelector(
    (state: ApplicationState) => state.currentUser.data.id
  );

  const users = useSelector((state: ApplicationState) => state.users.data);

  const newUsers = useMemo(() => {
    return users.filter((user) => (!user?.baseUserId && user.id !== currentUserId))
  }, [users, currentUserId]);

  const handleNewUser = () => {
    dispatch(onClose())
    history.push('/v3/user/new');
  };

  return (
    <S.AccountsCollapse
      accordion
      expandIconPosition='right'
      onChange={(key) => setShowAvatarGroup(!key)}
      expandIcon={({ isActive }) =>
        isActive ? <Up size='18px' /> : <Down size='18px' />
      }
    >
      <S.AccountsCollapsePanel
        key='1'
        header={<BodyM $color='grey50'>Others accounts</BodyM>}
        extra={
          showAvatarGroup &&
          newUsers &&
          newUsers?.length && <AccountsAvatarGroup users={newUsers} />
        }
      >
        {newUsers?.map((user) => {
          const types = [
            user?.type,
            ...(Array.isArray(user?.types) ? user.types : []),
          ].filter(Boolean) as Array<UserType>;

          return (
            <S.AccountsCollapseMain key={user.id}>
              <AccountCard
                user={user}
                types={types}
                isMember={user.membershipType === 'OFFICIAL'}
              />
            </S.AccountsCollapseMain>
          );
        })}
        <S.AccountsCollapseMain>
          <S.AccountsCollapseAddUserWrapper onClick={handleNewUser}>
            <S.AddUserButton>
              <Plus size='22px' />
            </S.AddUserButton>
            <BodyMBold $color='grey50'>New user</BodyMBold>
          </S.AccountsCollapseAddUserWrapper>
        </S.AccountsCollapseMain>
      </S.AccountsCollapsePanel>
      <S.AccountsCollapseFooter>
        <OutlinedButton
          color='white-dark'
          onClick={() => history.push('/v3/logout')}
        >
          Logout
        </OutlinedButton>
      </S.AccountsCollapseFooter>
    </S.AccountsCollapse>
  );
};
