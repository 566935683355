import React, { useCallback, useMemo, useState } from 'react';

import { CloseSmall } from '@icon-park/react';

import { BodyM, BodyXL, TitleH3 } from 'styles/v3/variables';

import UserType from 'models/User/UserTypeModel';
import { useProfile } from 'hooks/v3/profile/useProfile';
import InputCheckbox from 'components/v3/Forms/InputCheckbox/InputCheckbox';
import FilledButton from 'components/v3/Buttons/FilledButton';
import useClubPermission from 'hooks/v3/clubs/useClubPermission';
import { useGetMyRequestsToClub } from 'hooks/v3/requests/useGetMyRequestsToClub/useGetMyRequestsToClub';
import { RequestStatus } from 'models/Request/RequestModel';
import { useRequestJoinAndCreateRule } from 'hooks/v3/clubs/useRequestJoinAndCreateRule/useRequestJoinAndCreateRule';
import * as S from './styles';
import { JoinModalProps } from './types';
import { allowedRoles } from './allowedRoles';

export const JoinModal = ({ isOpen, handleIsOpen, club }: JoinModalProps) => {
  const { currentUser } = useProfile();

  const { isCoachOfThisClub, isPlayerOfThisClub } = useClubPermission(club);
  const { data: myClubRequests } = useGetMyRequestsToClub({
    clubId: club?.id,
    status: [RequestStatus.PENDING],
  });

  const {
    mutateAsync: requestJoin,
    isLoading: isRequesting,
  } = useRequestJoinAndCreateRule();

  const alreadyAppliedAs = useMemo(() => {
    return (
      myClubRequests?.content
        ?.map((r) => r?.user?.type)
        ?.filter((type) => type !== undefined) || []
    );
  }, [myClubRequests]);

  const [coachSelected, setCoachSelected] = useState(false);
  const [playerSelected, setPlayerSelected] = useState(false);

  const handleJoinClub = () => {
    if (isRequesting) return;
    let role: 'all' | 'coach' | 'player' | null = null;
    if (coachSelected && playerSelected) {
      role = 'all';
    } else if (coachSelected) {
      role = 'coach';
    } else if (playerSelected) {
      role = 'player';
    }

    setCoachSelected(false);
    setPlayerSelected(false);

    if (!role) return;

    requestJoin({
      clubId: club.id,
      role,
    }).then(() => {
      handleIsOpen(false);
    });
  };

  const roleIsEnabled = useCallback(
    (roleType: string): boolean => {
      if (!club.allowApplications) return false;
      const type: UserType =
        roleType === 'COACH' ? UserType.COACH : UserType.PLAYER;

      if (type === UserType.COACH) {
        return !alreadyAppliedAs.includes(UserType.COACH) && !isCoachOfThisClub;
      }
      if (type === UserType.PLAYER) {
        return (
          !alreadyAppliedAs.includes(UserType.PLAYER) && !isPlayerOfThisClub
        );
      }
      return false;
    },
    [club, currentUser, myClubRequests]
  );

  const hasCheckedMember = useMemo(() => {
    return coachSelected || playerSelected;
  }, [coachSelected, playerSelected]);

  const handleSelectMember = (roleType: string, enabled: boolean) => {
    if (!enabled) return;

    if (roleType === 'COACH') {
      setCoachSelected(!coachSelected);
    } else {
      setPlayerSelected(!playerSelected);
    }
  };

  return (
    <S.StyledModal
      centered
      footer={false}
      closable={false}
      visible={isOpen}
      onOk={() => handleIsOpen(false)}
      onCancel={() => handleIsOpen(false)}
    >
      <S.Container>
        <S.HeaderWrapper>
          <TitleH3>Select how you want to join this club</TitleH3>
          <S.CloseButton onClick={() => handleIsOpen(false)}>
            <CloseSmall size='24px' />
          </S.CloseButton>
        </S.HeaderWrapper>

        <BodyM $color='grey400'>You can select more than one option.</BodyM>
        <S.RolesWrapper>
          {allowedRoles.map((role) => {
            const Icon = role.icon;

            const checked =
              role.name === 'COACH' ? coachSelected : playerSelected;
            const isEnabled = roleIsEnabled(role.name);
            return (
              <S.RoleCard
                key={role.name}
                isEnabled={isEnabled}
                onClick={() => handleSelectMember(role.name, isEnabled)}
              >
                <InputCheckbox
                  disabled={!isEnabled}
                  id={role.name}
                  name={role.name}
                  onChange={() => handleSelectMember(role.name, isEnabled)}
                  checked={checked}
                />
                <BodyXL>{role.title}</BodyXL>
                <Icon size={36} />
              </S.RoleCard>
            );
          })}
        </S.RolesWrapper>
        <FilledButton
          disabled={!hasCheckedMember || isRequesting}
          isUpper
          onClick={handleJoinClub}
        >
          {isRequesting ? 'Requesting...' : 'Request to Join'}
        </FilledButton>
      </S.Container>
    </S.StyledModal>
  );
};
