import { useState } from 'react';
import moment, { Moment } from 'moment';

type Params = {
  startDate: string | Date,
  endDate: string | Date,
};

export const useGetFormConfig = (params: Params) => {
  const { startDate, endDate } = params;

  const [showTime, setShowTime] = useState<boolean>(false);

  const countOfDays = moment(endDate).diff(startDate, 'days') + 1;
  const daysArray: string[] = [];

  for (let i = 0; i < countOfDays; i += 1) {
    daysArray.push(moment(startDate).add(i, 'days').format('YYYY-MM-DD'));
  }

  const convertToDailyEventsArray = (data?: any[]) => {
    const array: any[] = [];

    data?.forEach((field) => {
      const key = Object.keys(field);
      const times: { [k: string]: Moment[] }[][] = Object.values(field);
      const timesArray = times[0];

      key?.length &&
        timesArray?.length &&
        array.push({
          day: key[0],
          startTime: timesArray[0]
            ? moment(timesArray[0]).format('HH:mm:ss')
            : '',
          endTime: timesArray[1]
            ? moment(timesArray[1]).format('HH:mm:ss')
            : '',
        });
    });

    return array;
  };

  return {
    showTime,
    daysArray,
    countOfDays,
    setShowTime,
    convertToDailyEventsArray,
  };
};
