import React, { useCallback } from 'react';

import { CloseSmall } from '@icon-park/react';

import { BodyXL, TitleH3 } from 'styles/v3/variables';
import { useHistory, useParams } from 'react-router-dom';

import UserType from 'models/User/UserTypeModel';
import { useGetEvent } from 'hooks/v3/event/useGetEvent/useGetEvent';
import { useProfile } from 'hooks/v3/profile/useProfile';
import * as S from './styles';
import { allowedRoles } from './allowedRoles';

type ApplyModalProps = {
  isOpen: boolean;
  handleIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const ApplyModal = ({ isOpen, handleIsOpen }: ApplyModalProps) => {
  const history = useHistory();
  const params: { eventId: string } = useParams();

  const { data: eventInfo } = useGetEvent(params.eventId);

  const { currentUser } = useProfile();

  const handleRedirect = (roleType: string) => {
    if (!roleIsEnabled(roleType)) return;

    const applyPage =
      roleType === 'CLUB_DIRECTOR'
        ? 'signup-as-club-director'
        : 'signup-as-referee';
    history.push(`/v3/event/${params.eventId}/${applyPage}`);
  };

  const roleIsEnabled = useCallback(
    (roleType: string): boolean => {
      if (roleType === 'REFEREE') {
        if (
          eventInfo?.data?.data?.generalRules?.refereeConditions
            ?.allowRefereesToSignUp
        ) {
          return true;
        }
        return false;
      }

      const type: UserType =
        roleType === 'CLUB_DIRECTOR'
          ? UserType.CLUB_DIRECTOR
          : UserType.REFEREE;

      return (
        currentUser?.type === type ||
        currentUser?.types?.includes(type) ||
        false
      );
    },
    [eventInfo, currentUser]
  );

  return (
    <S.StyledModal
      centered
      footer={false}
      closable={false}
      visible={isOpen}
      onOk={() => handleIsOpen(false)}
      onCancel={() => handleIsOpen(false)}
    >
      <S.Container>
        <S.HeaderWrapper>
          <TitleH3>Apply as</TitleH3>
          <S.CloseButton onClick={() => handleIsOpen(false)}>
            <CloseSmall size='24px' />
          </S.CloseButton>
        </S.HeaderWrapper>

        <S.RolesWrapper>
          {allowedRoles.map((role) => {
            const Icon = role.icon;

            return (
              <S.RoleCard
                key={role.name}
                isEnabled={roleIsEnabled(role.name)}
                onClick={() => handleRedirect(role.name)}
              >
                <Icon size={80} />
                <BodyXL>{role.title}</BodyXL>
              </S.RoleCard>
            );
          })}
        </S.RolesWrapper>
      </S.Container>
    </S.StyledModal>
  );
};
