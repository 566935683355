import { useMutation, useQueryClient } from '@tanstack/react-query';
import { notification } from 'components/v3/Notification/notification';
import { useProfile } from 'hooks/v3/profile/useProfile';

import RequestService from 'services/v3/Request/RequestService';
import { defaultGetMyRequestsToClubProps } from 'services/v3/Request/types';

type UseRequestJoinAndCreateRuleProps = {
  clubId: string,
  role: 'all' | 'coach' | 'player',
};
export const useRequestJoinAndCreateRule = () => {
  const queryClient = useQueryClient();

  const {
    currentUser: { accountId, types },
    updateCurrentAccount,
  } = useProfile();

  return useMutation({
    mutationFn: async ({ clubId, role }: UseRequestJoinAndCreateRuleProps) =>
      await RequestService.requestJoinAndCreateRole(clubId, role),
    onSuccess: async (_, { clubId }) => {
      notification.success({
        message: 'Request successfully sent!',
        description:
          'Your request has been sent successfully, please wait for the club to get back to you',
      });

      updateCurrentAccount();
      queryClient.invalidateQueries(['get-my-request-to-club', clubId]);
    },
    onError: (error: any) => {
      notification.error({
        message: 'An error occurs!',
        description: error.message,
      });
    },
  });
};
