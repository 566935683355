import React, { useCallback } from 'react';

import { useGetJoinedEvents } from 'hooks/v3/event/useGetJoinedEvents/useGetJoinedEvents';
import MyEventCard from 'components/v3/Cards/MyEventCard/MyEventCard';
import * as S from './styles';

export const MyEvents = () => {
  const { data: response } = useGetJoinedEvents();

  const permission = useCallback(
    (eventId: string) => {
      const isMember = (
        type: 'playersIds' | 'coachesIds' | 'refereesIds'
      ): boolean => {
        return (
          response?.data?.content
            ?.filter((event) => event.id === eventId)
            .some((filteredEvent) => {
              const selectedTypeIds: string[] = filteredEvent?.[type] || [];
              return filteredEvent.myIds?.some((i) =>
                selectedTypeIds.includes(i)
              );
            }) || false
        );
      };
      return {
        isPlayer: isMember('playersIds'),
        isCoach: isMember('coachesIds'),
        isReferee: isMember('refereesIds'),
      };
    },
    [response]
  );

  return (
    <S.EventsList>
      {response?.data?.content?.map((item) => {
        const eventStatus = item.draft ? 'draft' : 'published';
        return (
          <MyEventCard
            size='small'
            key={item.id}
            id={item.id}
            logo={item.logo}
            title={item.name}
            orientation='column'
            actionText='See event'
            eventStatus={eventStatus}
            description={item.description}
            startDate={item.startDate}
            finalDate={item.endDate}
            link={`/v3/event/${item.id}`}
            permission={permission(item.id)}
          />
        );
      })}
    </S.EventsList>
  );
};
