import React, {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Camera, IdCardH, IdCardV, PreviewOpen } from '@icon-park/react';

import { User } from 'models/User/UserModel';
import UserType from 'models/User/UserTypeModel';
import { RequestStatus } from 'models/Request/RequestModel';

import { useUpdatePlayerNumber } from 'hooks/v3/rosters/useUpdatePlayerNumber/useUpdatePlayerNumber';

import { UserWithJersey } from 'pages/V3/Roster/RosterEditPage/types';
import IconButton from 'components/v3/Buttons/IconButton';
import Logo from 'components/v3/Elements/Logo/Logo';

import ConvertUtil from 'util/ConvertUtil';

import { BodyXL, BodyM, COLORS, BodyS } from 'styles/v3/variables';

import {
  ContainerUser,
  UserInformationContent,
  UserIconName,
  UserImage,
  UserMemberType,
  UserDocumentsAndMore,
  UserDocuments,
  UserDocument,
  JerseyField,
} from './styles';

interface IPlayerOfCoachInformationProps {
  user: UserWithJersey;
  rosterId?: Maybe<string>;
  handleOpenUserInfoModal: (user: User) => void;
}

export const PlayerOfCoachInformation: FC<IPlayerOfCoachInformationProps> = ({
  user,
  rosterId,
  handleOpenUserInfoModal,
}) => {
  const {
    mutateAsync: updatePlayerNumber,
    isLoading,
  } = useUpdatePlayerNumber();

  const [jerseyValue, setJerseyValue] = useState<string>('');

  const jerseyStoredNumber = useMemo(() => {
    if (typeof user.jersey !== 'undefined' && user.jersey < 10) {
      return `0${user.jersey}`;
    }

    return user.jersey?.toString() ?? '';
  }, [user]);

  const getColorTheme = useCallback(() => {
    if (user.type === UserType.PLAYER) {
      return `grey800`;
    }

    return user.photoVerified !== RequestStatus.DECLINED
      ? 'grey400'
      : 'supportError';
  }, [user]);

  const handleChangeJerseyNumber = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;

      const sanitizedValue = value.replace(/[^0-9]/g, '');

      setJerseyValue(sanitizedValue);
    },
    []
  );

  const handleStoreJerseyNumber = useCallback(() => {
    const inputJerseyNumber =
      jerseyValue.length > 1 ? jerseyValue : `0${jerseyValue}`;

    if (rosterId && !isLoading && jerseyStoredNumber !== inputJerseyNumber) {
      updatePlayerNumber({
        number: Number(jerseyValue),
        playerId: user.id,
        rosterId,
      }).catch(() => {
        setJerseyValue(jerseyStoredNumber);
      });
    }

    if (jerseyStoredNumber === inputJerseyNumber) {
      setJerseyValue(jerseyStoredNumber);
    }
  }, [rosterId, jerseyValue, isLoading, jerseyStoredNumber]);

  useEffect(() => {
    setJerseyValue(jerseyStoredNumber);
  }, [jerseyStoredNumber]);

  return (
    <ContainerUser>
      <UserInformationContent>
        <UserIconName>
          <UserImage>
            <img
              src={ConvertUtil.getMinioUrl(user.photo)}
              alt='User Profile pic'
            />
          </UserImage>
          <BodyXL>
            {user.firstName} {user.lastName}
          </BodyXL>
        </UserIconName>
        <UserMemberType>
          <Logo
            width={20}
            height={20}
            minimal
            color={
              user.membership?.type === 'OFFICIAL' ? 'brandPrimary' : 'grey600'
            }
          />
          <BodyM
            $color={user.membership?.type === 'OFFICIAL' ? 'grey50' : 'grey300'}
          >
            {user.membership?.type === 'OFFICIAL'
              ? 'Official Member'
              : 'Community Member'}
          </BodyM>
        </UserMemberType>
      </UserInformationContent>
      <UserDocumentsAndMore>
        <UserDocuments>
          <UserDocument>
            <Camera
              fill={
                user.photoVerified !== RequestStatus.DECLINED
                  ? COLORS.grey400
                  : COLORS.supportError
              }
            />
            <BodyS
              $color={
                user.photoVerified !== RequestStatus.DECLINED
                  ? 'grey400'
                  : 'supportError'
              }
            >
              PH
            </BodyS>
          </UserDocument>
          <UserDocument>
            <IdCardH
              fill={
                user.photoVerified !== RequestStatus.DECLINED
                  ? COLORS.grey400
                  : COLORS.supportError
              }
            />
            <BodyS
              $color={
                user.photoVerified !== RequestStatus.DECLINED
                  ? 'grey400'
                  : 'supportError'
              }
            >
              AV
            </BodyS>
          </UserDocument>
          <UserDocument>
            <IdCardV fill={COLORS[getColorTheme()]} />
            <BodyS $color={getColorTheme()}>SS</BodyS>
          </UserDocument>
        </UserDocuments>
        {user.type === UserType.PLAYER && (
          <JerseyField
            id='jersey'
            label='Jersey'
            placeholder='Jersey'
            value={jerseyValue}
            disabled={isLoading}
            onChange={handleChangeJerseyNumber}
            onBlur={handleStoreJerseyNumber}
          />
        )}
        <IconButton
          icon={<PreviewOpen size={24} fill={COLORS.grey50} />}
          style={{ backgroundColor: COLORS.grey900 }}
          onClick={() => handleOpenUserInfoModal(user)}
        />
      </UserDocumentsAndMore>
    </ContainerUser>
  );
};
