import { useQuery } from '@tanstack/react-query';
import CouponService from 'services/v3/Coupons/CouponServices';

export function useGetProductCoupons(productId?: string) {
  const { data, isLoading, error } = useQuery(
    ['get-product-coupons', productId],
    () => CouponService.getProductCoupons(productId),
    {
      retry: 1,
      refetchOnWindowFocus: false,
    }
  );

  return {
    ...data,
    data: data?.data ?? null,
    isLoading,
    error,
  };
}
