import * as React from 'react';
import { withRouter, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { PatchEventProps } from 'services/v3/B2bEvent/types';
import { ApplicationState } from 'redux/store';
import { sideModalCloseModal } from 'redux/sideModal/actions';

import { useUpdateEvent } from 'hooks/v3/event/useUpdateEvent/useUpdateEvent';
import { formatCurrencyValues } from 'util/CurrencyUtil';
import { DeepPartial } from 'util/types';

import FilledButton from 'components/v3/Buttons/FilledButton';

import { CoachesRules } from './components/CoachesRules';
import { coachesOptions, currencyOptions, playersOptions } from './constants';
import { PlayerRules } from './components/PlayerRules';
import { RefereeConditions, RosterRules } from './components';
import { Fields } from './types';
import * as S from './styles';

const GeneralRules: React.FunctionComponent = () => {
  const { eventData } = useSelector(
    (state: ApplicationState) => state.b2bSetupEvent
  );

  const dispatch = useDispatch();

  const eventInfoData: DeepPartial<PatchEventProps> = React.useMemo(
    () => ({
      minimumPlayersNumber: eventData.minimumPlayersNumber ?? 0,
      maximumPlayersNumber: eventData.maximumPlayersNumber ?? 1,
      minimumCoachesNumber: eventData.minimumCoachesNumber ?? 0,
      maximumCoachesNumber: eventData.maximumCoachesNumber ?? 1,
      data: {
        generalRules: {
          rosterLockDate:
            eventData.data?.generalRules?.rosterLockDate ??
            new Date().toISOString().split('T')[0],
          allowPlayersOnDifferentTeams:
            eventData.data?.generalRules?.allowPlayersOnDifferentTeams ?? false,
          allowPlayersOnDifferentClubs:
            eventData.data?.generalRules?.allowPlayersOnDifferentClubs ?? false,
          playerAgeDivisionPermission:
            eventData.data?.generalRules?.playerAgeDivisionPermission ?? '',
          allowCoachesOnDifferentTeams:
            eventData.data?.generalRules?.allowCoachesOnDifferentTeams ?? false,
          allowCoachesOnDifferentClubs:
            eventData.data?.generalRules?.allowCoachesOnDifferentClubs ?? false,
          coachAgeDivisionPermission:
            eventData.data?.generalRules?.coachAgeDivisionPermission ?? '',
          allowMultipleTeamsSameClubAndSameAge:
            eventData.data?.generalRules
              ?.allowMultipleTeamsSameClubAndSameAge ?? false,
          refereeConditions: {
            allowRefereesToSignUp:
              eventData.data?.generalRules?.refereeConditions
                ?.allowRefereesToSignUp ?? false,
            paymentCurrency:
              eventData.data?.generalRules?.refereeConditions
                ?.paymentCurrency ?? '',
            refereePair:
              eventData.data?.generalRules?.refereeConditions?.refereePair ??
              '',
            refereeSolo:
              eventData.data?.generalRules?.refereeConditions?.refereeSolo ??
              '',
            requirements:
              eventData.data?.generalRules?.refereeConditions?.requirements ??
              '',
            scoreKeeper:
              eventData.data?.generalRules?.refereeConditions?.scoreKeeper ??
              '',
            travelConditions:
              eventData.data?.generalRules?.refereeConditions
                ?.travelConditions ?? '',
          },
        },
      },
    }),
    [eventData]
  );

  const stateFields: Fields = {
    refereePair: {
      error: false,
      required: true,
      value:
        eventInfoData.data?.generalRules?.refereeConditions?.refereePair ?? '',
    },
    refereeSolo: {
      error: false,
      required: true,
      value:
        eventInfoData.data?.generalRules?.refereeConditions?.refereeSolo ?? '',
    },
    scoreKeeper: {
      error: false,
      required: true,
      value:
        eventInfoData.data?.generalRules?.refereeConditions?.scoreKeeper ?? '',
    },
  };

  const [fields, setFields] = React.useState<Fields>(stateFields);

  const [allowPlayerTeam, setAllowPlayerTeam] = React.useState<boolean>(
    eventInfoData.data?.generalRules?.allowPlayersOnDifferentTeams ?? false
  );

  const [allowPlayerClub, setAllowPlayerClub] = React.useState<boolean>(
    eventInfoData.data?.generalRules?.allowPlayersOnDifferentClubs ?? false
  );

  const [allowCoachTeam, setAllowCoachTeam] = React.useState<boolean>(
    eventInfoData.data?.generalRules?.allowCoachesOnDifferentTeams ?? false
  );

  const [allowCoachClub, setAllowCoachClub] = React.useState<boolean>(
    eventInfoData.data?.generalRules?.allowCoachesOnDifferentClubs ?? false
  );

  const [
    allowMultipleTeamsSameClubAndSameAge,
    setAllowMultipleTeamsSameClubAndSameAge,
  ] = React.useState<boolean>(
    eventInfoData.data?.generalRules?.allowMultipleTeamsSameClubAndSameAge ??
      false
  );

  const [allowRefereesToSignUp, setAllowRefereesToSignUp] = React.useState<
    boolean
  >(
    eventInfoData.data?.generalRules?.refereeConditions
      ?.allowRefereesToSignUp ?? false
  );

  const setLabelToPlayersPermission = playersOptions.find(
    (p) =>
      p.value === eventInfoData.data?.generalRules?.playerAgeDivisionPermission
  );

  const setLabelToCoachesPermission = coachesOptions.find(
    (p) =>
      p.value === eventInfoData.data?.generalRules?.coachAgeDivisionPermission
  );

  const setLabelToPaymentCurrency = currencyOptions.find(
    (p) =>
      p.value ===
      eventInfoData.data?.generalRules?.refereeConditions?.paymentCurrency
  );

  const formRef = React.useRef<HTMLFormElement>(null);
  const params: { eventId: string } = useParams();
  const eventId: string = params?.eventId || '';

  const { isLoading, mutateAsync } = useUpdateEvent({ eventId });

  const isFieldKey = (key: string): key is keyof Fields => {
    return key in stateFields;
  };

  const handleChangeInputValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fieldName = e.target.name as keyof Fields;
    const newValue = e.target.value;

    if (isFieldKey(fieldName)) {
      setFields((prevFields) => ({
        ...prevFields,
        [fieldName]: {
          ...prevFields[fieldName],
          error: false,
          value: newValue,
        },
      }));
    }
  };

  const validate = () => {
    let hasError = false;

    for (const [key, values] of Object.entries(fields)) {
      if (values.value === '' && values.required) {
        hasError = true;

        setFields((newFields) => ({
          ...newFields,
          [key]: {
            error: true,
            value: values.value,
            required: values.required,
          },
        }));
      }
    }

    if (!hasError) handleSave();
  };

  const handleSave = async () => {
    try {
      if (formRef.current) {
        const formData: FormData = new FormData(formRef.current);

        const payload: DeepPartial<PatchEventProps> = {
          minimumPlayersNumber: Number(formData.get('minPlayerRules')),
          maximumPlayersNumber: Number(formData.get('maxPlayerRules')),
          minimumCoachesNumber: Number(formData.get('minCoachesRules')),
          maximumCoachesNumber: Number(formData.get('maxCoachesRules')),

          data: {
            generalRules: {
              rosterLockDate: formData.get('rosterLockDate') as string,
              allowPlayersOnDifferentTeams: Boolean(
                formData.get('allowPlayersOnDifferentTeams')
              ),
              allowPlayersOnDifferentClubs: Boolean(
                formData.get('allowPlayersOnDifferentClubs')
              ),
              playerAgeDivisionPermission: formData.get(
                'playerAgeDivisionPermission'
              ) as string,
              allowCoachesOnDifferentTeams: Boolean(
                formData.get('allowCoachesOnDifferentTeams')
              ),
              allowCoachesOnDifferentClubs: Boolean(
                formData.get('allowCoachesOnDifferentClubs')
              ),
              coachAgeDivisionPermission: formData.get(
                'coachAgeDivisionPermission'
              ) as string,
              allowMultipleTeamsSameClubAndSameAge: Boolean(
                formData.get('allowMultipleTeamsSameClubAndSameAge')
              ),
              refereeConditions: {
                allowRefereesToSignUp: Boolean(
                  formData.get('allowRefereesToSignUp')
                ),
                paymentCurrency: formData.get('paymentCurrency') as string,
                refereeSolo: formatCurrencyValues(
                  formData.get('refereeSolo')?.toString() as string
                ),
                refereePair: formatCurrencyValues(
                  formData.get('refereePair')?.toString() as string
                ),
                scoreKeeper: formatCurrencyValues(
                  formData.get('scoreKeeper')?.toString() as string
                ),
                requirements: formData.get('requirements') as string,
                travelConditions: formData.get('travelConditions') as string,
              },
            },
          },
        };

        await mutateAsync({ id: eventId, data: payload });

        dispatch(sideModalCloseModal());
      }
    } catch (e) {
      console.error('Error updating event', e);
    }
  };

  return (
    <S.GeneralRulesForm
      ref={formRef}
      id='generalRules'
      onSubmit={(e) => e.preventDefault()}
    >
      <RosterRules
        rosterLockDate={
          eventInfoData.data?.generalRules?.rosterLockDate as string
        }
        allowMultipleTeamsSameClubAndSameAge={
          allowMultipleTeamsSameClubAndSameAge
        }
        setAllowMultipleTeamsSameClubAndSameAge={
          setAllowMultipleTeamsSameClubAndSameAge
        }
      />

      <RefereeConditions
        fields={fields}
        allowRefereesToSignUp={allowRefereesToSignUp}
        handleChangeInputValue={handleChangeInputValue}
        setAllowRefereesToSignUp={setAllowRefereesToSignUp}
        setLabelToPaymentCurrency={setLabelToPaymentCurrency}
        requirements={
          eventInfoData.data?.generalRules?.refereeConditions?.requirements
        }
        travelConditions={
          eventInfoData.data?.generalRules?.refereeConditions?.travelConditions
        }
      />

      <PlayerRules
        allowPlayerTeam={allowPlayerTeam}
        allowPlayerClub={allowPlayerClub}
        setAllowPlayerTeam={setAllowPlayerTeam}
        setAllowPlayerClub={setAllowPlayerClub}
        maximumPlayersNumber={eventInfoData.maximumPlayersNumber}
        minimumPlayersNumber={eventInfoData.minimumPlayersNumber}
        setLabelToPlayersPermission={setLabelToPlayersPermission}
      />

      <CoachesRules
        allowCoachTeam={allowCoachTeam}
        allowCoachClub={allowCoachClub}
        setAllowCoachClub={setAllowCoachClub}
        setAllowCoachTeam={setAllowCoachTeam}
        maximumCoachesNumber={eventInfoData.maximumCoachesNumber}
        minimumCoachesNumber={eventInfoData.minimumCoachesNumber}
        setLabelToCoachesPermission={setLabelToCoachesPermission}
      />

      <S.ButtonWrapper>
        <FilledButton onClick={validate} isLoading={isLoading}>
          {isLoading ? '...' : 'Save'}
        </FilledButton>
      </S.ButtonWrapper>
    </S.GeneralRulesForm>
  );
};

export default withRouter(GeneralRules);
