import { createGlobalStyle } from 'styled-components';
import { COLORS } from './variables';
import { toRem } from './functions';
import { BREAKPOINTS } from './constants';

export default createGlobalStyle`

  .ant-notification-notice {
    border-radius: ${toRem(16)};
    background-color: ${COLORS.grey900};
  }

  .ant-notification-notice-description {
    max-width: ${toRem(235)};
  }

  .ant-notification-close-x {
    padding: ${toRem(6)} ${toRem(8)};
    border-radius: ${toRem(8)};
    background-color: ${COLORS.grey800};
  }

  .ant-dropdown-menu {
    padding: ${toRem(4)};
    border-radius: ${toRem(12)};
    background-color: ${COLORS.grey950};

    border: 1px solid ${COLORS.grey800};
  }

  .ant-dropdown-menu-item-selected {
    color: ${COLORS.grey50};
    background-color: ${COLORS.grey900};
  }

  .ant-dropdown-menu-item:hover, .ant-dropdown-menu-submenu-title:hover {
    background-color: ${COLORS.grey900};
  }

  .ant-switch {
    &-checked {
      background-color: ${COLORS.brandPrimary};
    }
    &-handle {
      &::before {
        background-color: ${COLORS.grey900};
      }
    }
  }

  .ant-tabs-nav {
    ::before {
      display: none;
    }
  }

  .ant-tabs-ink-bar {
    display: none;
  }

  .ant-tabs-nav-list {
    gap: 24px;
  }

  .ant-tabs-ink-bar {
    display: block !important;
    visibility: visible !important;
    background: ${COLORS.brandPrimary};
  }

  .ant-tabs-tab {
    padding: 0 !important;
    border: none !important;
    margin-bottom: 8px !important;
    background-color: unset !important;
  }

  .ant-tabs-tab:hover {
    color: ${COLORS.brandPrimary} !important;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${COLORS.brandPrimary} !important;
  }

  .ant-tabs-tab-btn:active {
    color: ${COLORS.brandPrimary};
  }

  .ant-pagination-item-link {
    border: none !important;
    font-size: 16px;
    color: ${COLORS.grey50};
  }

  .ant-pagination-next:hover .ant-pagination-item-link {
    color: ${COLORS.grey300};
  }

  .ant-pagination-prev:hover .ant-pagination-item-link {
    color: ${COLORS.grey300};
  }

  .ant-pagination-item {
    border: none;
    border-radius: 8px;
    color: ${COLORS.grey50};

    a {
      &:hover {
        color: ${COLORS.grey50};
      }
    }

    &:hover {
      border: none;
      background-color: ${COLORS.grey700};
    }
  }

  .ant-pagination-item-active {
    border: none;
    border-radius: 8px;
    background-color: ${COLORS.grey700};

    a {
      font-size: 13px;
      font-family: 'Boxed Regular';

      color: ${COLORS.grey50};

      &:hover {
        border: none;
        color: ${COLORS.grey50};
      }
    }
  }

  .ant-pagination-item-active:focus a {
    color: ${COLORS.grey50};
  }

  .ant-pagination-jump-next
  .ant-pagination-item-container
  .ant-pagination-item-link-icon {
    color: ${COLORS.grey100};
  }

  .ant-pagination-jump-prev
  .ant-pagination-item-container
  .ant-pagination-item-link-icon {
    color: ${COLORS.grey100};
  }

  .ant-drawer-content-wrapper {
    width: 500px !important;

    @media screen and (max-width: ${BREAKPOINTS.mobile}) {
      width: 100% !important;
    }
  }

  .ant-drawer-header {
    border: none;
    background: ${COLORS.grey950};
  }

  .ant-drawer-body {
    background: ${COLORS.grey950};
  }

  .ant-drawer-footer {
    background: ${COLORS.grey950};
    border-top: 1px solid ${COLORS.grey800};
  }

  .ant-drawer-title {
    color: ${COLORS.brandPrimary};
  }

  .ant-drawer-close {
    padding: 8px;
    margin: 10px 16px;
    border-radius: 12px;
    background-color: ${COLORS.grey800};
  }

  .ant-drawer-header-no-title .ant-drawer-close {
    padding-right: calc(20px - 12px) ;
  }

  .ant-tooltip-inner {
    font-weight: bold;
  }

  .ant-modal-wrap  {
    @media screen and (max-width: ${BREAKPOINTS.mobile}) {
      top: auto;
      margin-bottom: ${toRem(72)};
    }
  }

  .ant-modal {
    @media screen and (max-width: ${BREAKPOINTS.mobile}) {
      width: 100%;
      max-width: 100%;
      padding-bottom: 0px;
      margin: 0;
    }
  }

  .ant-modal-content {
    max-width: 420px;
    border-radius: 20px;
    background-color: ${COLORS.grey900};

    @media screen and (max-width: ${BREAKPOINTS.mobile}) {
      border-radius: 0px;
      border-top-left-radius: ${toRem(24)};
      border-top-right-radius: ${toRem(24)};
    }

    button {
      top: 18px;
      right: 16px;
      padding: 4.4px 0;

      margin-bottom: 8px;
      
      background-color: ${COLORS.grey700};

      &:hover {
        background-color: ${COLORS.grey600};
      }

      span {
        width: 38px;
        height: 38px;

        border: none;
        box-shadow: none;
        padding: 2.4px 0;
        line-height: 38px;
        border-radius: 10px;
        color: ${COLORS.grey50};

        cursor: pointer;

        transition: all 0.3s;
      }
    }
  }

  .ant-modal-header {
    border-radius: 24px;
    background-color: ${COLORS.grey900};
  }

  .ant-tag {
    border-radius: 24px;
    padding: 6px 12px 6px 12px;
  }

  .ant-collapse {
    border: none;
  }

  .ant-collapse > .ant-collapse-item {
    border: none !important;
  }

  .ant-collapse-content-box {
    overflow-y: auto;
    padding-top: 4px;

    ::-webkit-scrollbar {
      width: 2px;
      height: 2px;
    }
    ::-webkit-scrollbar-button {
      width: 0px;
      height: 0px;
    }
    ::-webkit-scrollbar-thumb {
      background: #e1e1e1;
      border: 0px none #ffffff;
      border-radius: 50px;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: #ffffff;
    }
    ::-webkit-scrollbar-thumb:active {
      background: #000000;
    }
    ::-webkit-scrollbar-track {
      background: #666666;
      border: 0px none #ffffff;
      border-radius: 50px;
    }
    ::-webkit-scrollbar-track:hover {
      background: #666666;
    }
    ::-webkit-scrollbar-track:active {
      background: #333333;
    }
    ::-webkit-scrollbar-corner {
      background: transparent;
    }
  }

  .ant-collapse-header {
    display: flex;
    align-items: center;
    flex-direction: row;

    min-height: 57px;
  }

  .ant-collapse-extra {
    display: flex;
    margin-right: 14px;
  }

  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
  }

  .ant-collapse-content-box {
    padding: 0;
    background-color: ${COLORS.grey950};
  }

  .ant-collapse-content {
    border: none;
  }
`;
