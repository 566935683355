import { OptionsType } from 'components/v3/Forms/Select/Select';

export const playersOptions: OptionsType[] = [
  {
    value: 'not-allow-players',
    label: 'We are not allowing players to play in multiple teams',
  },
  {
    value: 'allow-players',
    label: 'Yes, players can play both in the same or different age divisions',
  },
  {
    value: 'allow-players-different-teams-different-divisions',
    label:
      'Players can play in different teams, as long as they are in different age divisions',
  },
  {
    value: 'allow-players-different-teams-same-divisions',
    label:
      'Players can play in different teams, as long as they are in same age divisions',
  },
];

export const coachesOptions: OptionsType[] = [
  {
    value: 'not-allow-coaches',
    label: 'We are not allowing coaches to coach in multiple teams',
  },
  {
    value: 'allow-coaches',
    label: 'Yes, coaches can coach both in the same or different age divisions',
  },
  {
    value: 'allow-coaches-different-teams-different-divisions',
    label:
      'Coaches can coach in different teams, as long as they are in different age divisions',
  },
  {
    value: 'allow-coaches-different-teams-same-divisions',
    label:
      'Coaches can coach in different teams, as long as they are in same age divisions',
  },
];

export const currencyOptions: OptionsType[] = [
  {
    value: 'USD',
    label: 'Dollar',
  },
];
