import { HeadsetOne, SoccerOne } from '@icon-park/react';

export const allowedRoles = [
  {
    title: 'Coach',
    name: 'COACH',
    icon: HeadsetOne,
  },
  {
    title: 'Player',
    name: 'PLAYER',
    icon: SoccerOne,
  },
];
