import React, { FunctionComponent } from 'react';
import { Camera, IdCardH, IdCardV } from '@icon-park/react';
import { User } from 'models/User/UserModel';
import ConvertUtil from 'util/ConvertUtil';

import { BodyXL, BodyM, COLORS, BodyS, ColorsInterface } from 'styles/v3/variables';
import Logo from 'components/v3/Elements/Logo/Logo';

import {
  ContainerUser,
  UserInformationContent,
  UserIconName,
  UserImage,
  UserMemberType,
  UserDocumentsAndMore,
  UserDocuments,
  UserDocument,
  UserJerseyContainer,
} from './styles';

interface PlayerOrCoachInformationProps {
  user: User
}

export const PlayerOfCoachInformation: FunctionComponent<PlayerOrCoachInformationProps> = ({ user }) => {
  const userSS = (u: User): keyof ColorsInterface => {
    if (u.type === 'PLAYER') {
      return `grey800`;
    }

    return u.photoVerified !== 'DECLINED' ? 'grey400' : 'supportError';
  };

  return (
    <ContainerUser>
      <UserInformationContent>
        <UserIconName>
          <UserImage>
            <img
              src={ConvertUtil.getMinioUrl(user.photo)}
              alt='User Profile pic'
            />
          </UserImage>
          <BodyXL>
            {user.firstName} {user.lastName}
          </BodyXL>
        </UserIconName>
        <UserMemberType>
          <Logo
            width={20}
            height={20}
            minimal
            color={
              user.membership?.type === 'OFFICIAL' ? 'brandPrimary' : 'grey600'
            }
          />
          <BodyM
            $color={user.membership?.type === 'OFFICIAL' ? 'grey50' : 'grey300'}
          >
            {user.membership?.type === 'OFFICIAL'
              ? 'Official Member'
              : 'Community Member'}
          </BodyM>
        </UserMemberType>
      </UserInformationContent>

      <UserDocumentsAndMore>
        <UserDocuments>
          <UserDocument>
            <Camera
              fill={
                user.photoVerified !== 'DECLINED'
                  ? COLORS.grey400
                  : COLORS.supportError
              }
            />
            <BodyS
              $color={
                user.photoVerified !== 'DECLINED' ? 'grey400' : 'supportError'
              }
            >
              PH
            </BodyS>
          </UserDocument>

          <UserDocument>
            <IdCardH
              fill={
                user.photoVerified !== 'DECLINED'
                  ? COLORS.grey400
                  : COLORS.supportError
              }
            />
            <BodyS
              $color={
                user.photoVerified !== 'DECLINED' ? 'grey400' : 'supportError'
              }
            >
              AV
            </BodyS>
          </UserDocument>

          <UserDocument>
            <IdCardV fill={COLORS[userSS(user)]} />
            <BodyS $color={userSS(user)}>SS</BodyS>
          </UserDocument>
        </UserDocuments>

        {user.type === 'PLAYER' && (
          <UserJerseyContainer>
            <BodyS $color='grey400'>Jersey</BodyS>
            <BodyM $color='white'>.</BodyM>
          </UserJerseyContainer>
        )}
      </UserDocumentsAndMore>
    </ContainerUser>
  );
};
