import Keycloak from 'keycloak-js';
import * as React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { ApplicationState } from 'redux/store';
import EventCard from 'components/v3/Cards/EventCard/EventCard';
import B2bEventService from 'services/v3/B2bEvent/B2bEventService';
import { b2bSetupEventFetchEventsSuccess } from 'redux/v3/b2bSetupEvent/actions';

import { EventPublishedStatus } from 'admin/models/event/Event';
import { B2bEventItem } from 'redux/v3/b2bSetupEvent/types';
import { B2bEventStatus } from 'models/v3/Event/Event';
import * as S from './styles';

interface CreatedByMeProps {
  auth: Keycloak.KeycloakInstance;
}
export const CreatedByMe = ({ auth }: CreatedByMeProps) => {
  const email = ((auth?.tokenParsed as any)?.email as string) ?? '';
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const dispatch = useDispatch();

  const { content: storedEvents } = useSelector(
    (state: ApplicationState) => state.b2bSetupEvent.data
  );

  const handleLoadEvents = async () => {
    setIsLoading(true);
    try {
      const { data: fetchedEvents } = await B2bEventService.listEvents(
        100
      );
      dispatch(b2bSetupEventFetchEventsSuccess(fetchedEvents));
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    handleLoadEvents();
  }, []);

  const getEventStatus = (currentEvent: B2bEventItem): B2bEventStatus => {
    if (!currentEvent?.data?.eventPublished) return 'draft'
    switch (currentEvent.data.eventPublished) {
      case EventPublishedStatus.PUBLISHED:
        return 'published'
      case EventPublishedStatus.PENDING:
        return 'waiting approval'
      case EventPublishedStatus.EXPIRED:
        return 'expired'
      default:
        return 'draft'
    } 
  }

  return (
    <>
      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <S.EventsList>
          {storedEvents?.map((i) => {
            const eventStatus = getEventStatus(i)            
            const linkToRedirect =
              eventStatus === 'published'
                ? `/v3/event/${i.id}/dashboard`
                : `/v3/setup-event/my-events/${i.id}`;

            return (
              <EventCard
                key={i.id}
                eventStatus={eventStatus}
                orientation='column'
                size='small'
                title={i.name}
                description={i.description}
                startDate={i.startDate}
                finalDate={i.endDate}
                id={i.id}
                logo={i.logo}
                divisions={i.ageDivisions}
                link={linkToRedirect}
              />
            );
          })}
        </S.EventsList>
      )}
    </>
  );
};
