import InputCheckbox from 'components/v3/Forms/InputCheckbox/InputCheckbox';
import InputText from 'components/v3/Forms/InputText/InputText';
import React from 'react';
import { BodyLBold, BodyM, BodyMBold } from 'styles/v3/variables';
import Select, { OptionsType } from 'components/v3/Forms/Select/Select';

import * as MainStyles from '../../styles';
import { playersOptions } from '../../constants';

type PlayerRulesProps = {
  allowPlayerTeam: boolean,
  allowPlayerClub: boolean,
  minimumPlayersNumber?: number,
  maximumPlayersNumber?: number,
  setLabelToPlayersPermission: OptionsType | undefined,
  setAllowPlayerTeam: React.Dispatch<React.SetStateAction<boolean>>,
  setAllowPlayerClub: React.Dispatch<React.SetStateAction<boolean>>,
};

export const PlayerRules = ({
  allowPlayerTeam,
  allowPlayerClub,
  setAllowPlayerTeam,
  setAllowPlayerClub,
  maximumPlayersNumber,
  minimumPlayersNumber,
  setLabelToPlayersPermission,
}: PlayerRulesProps) => {
  return (
    <fieldset>
      <legend>
        <BodyLBold $color='white'>Player Rules</BodyLBold>
      </legend>
      <InputText
        containerClassName='general-rules-input'
        id='minPlayerRules'
        placeholder='Min Number of Players'
        label='Min Number of Players'
        defaultValue={minimumPlayersNumber}
        type='number'
        min={0}
      />
      <InputText
        containerClassName='general-rules-input'
        id='maxPlayerRules'
        placeholder='Max Number of Players'
        label='Max Number of Players'
        defaultValue={maximumPlayersNumber}
        type='number'
        min={0}
      />
      <BodyMBold $color='grey200'>Permissions</BodyMBold>
      <MainStyles.ElementCheckboxWrapper>
        <InputCheckbox
          id='allowPlayersOnDifferentTeams'
          name='allowPlayersOnDifferentTeams'
          onChange={() => setAllowPlayerTeam(!allowPlayerTeam)}
          checked={allowPlayerTeam}
        />
        <BodyM>
          Allow players to play in{' '}
          <BodyMBold $color='brandPrimary'>different Teams</BodyMBold>
        </BodyM>
      </MainStyles.ElementCheckboxWrapper>
      {allowPlayerTeam && (
        <>
          <MainStyles.ElementCheckboxWrapper>
            <InputCheckbox
              id='allowPlayersOnDifferentClubs'
              name='allowPlayersOnDifferentClubs'
              checked={allowPlayerClub}
              onChange={() => setAllowPlayerClub(!allowPlayerClub)}
            />
            <BodyM>
              Allow players to play in{' '}
              <BodyMBold $color='brandPrimary'>different Clubs</BodyMBold>
            </BodyM>
          </MainStyles.ElementCheckboxWrapper>
          <Select
            name='playerAgeDivisionPermission'
            placeholder='Select Age Division Permission'
            defaultValue={setLabelToPlayersPermission}
            options={playersOptions}
          />
        </>
      )}
    </fieldset>
  );
};
