import styled from 'styled-components';
import { BREAKPOINTS } from 'styles/v3/constants';
import { toRem } from 'styles/v3/functions';

export const UpgradeMembershipPlansWrapper = styled.div`
  display: flex;
  flex-direction: column;

  text-align: center;

  h1 {
    margin: 0;
  }

  a {
    display: flex;
    align-items: center;

    span {
      font-size: initial;
      line-height: initial;
    }
  }

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    padding: ${toRem(48)} ${toRem(24)} 0;

    h1 {
      max-width: ${toRem(100)};
    }

    p {
      max-width: ${toRem(250)};
    }
  }
`;

export const CardsWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;

  margin-top: ${toRem(140)};

  > :first-child {
    margin-top: ${toRem(38)};
  }

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    align-items: center;
    flex-direction: column-reverse;

    gap: ${toRem(64)};
  }
`;
