import { AxiosRequestConfig } from 'axios';
import { ConfigApi } from '../../config.api';
import APIServicesV3 from '../../util/ApiServicesV3';
import ServiceResponseV3 from '../../util/ServiceResponseV3';
import { BuyEventProps, BuyEventReturn } from './types';

class B2bDraftEventService {
  public static async buyEvent(
    data: BuyEventProps
  ): Promise<ServiceResponseV3<BuyEventReturn>> {
    if (!data.eventData.selectedEvent) {
      throw new Error('No selected event');
    }
    const b2bEventDraft = {
      type: data.eventData?.selectedEvent?.type,
      ...data.eventData.eventInfo,
      startDate: dateTime2DateStr(data.eventData.eventInfo.startDate),
      endDate: dateTime2DateStr(data.eventData.eventInfo.endDate),
    };
    const axiosConfig: AxiosRequestConfig = {
      // ...ConfigApi.getBearerConfig(),
      ...ConfigApi.getNoAccountConfig(),
      url: '/orders',
      method: 'POST',
      data: {
        email: data.userEmail,
        cancelUrl: '/v3/draft-event/general-event',
        type: 'B2B_EVENT',
        cart: [
          {
            b2bEvent: {
              draft: b2bEventDraft,
            },
            productId: data?.eventData?.selectedEvent?.id,
            type: 'B2B_EVENT',
            quantity: 1,
          },
        ],
        couponId: '',
      },
    };
    return APIServicesV3.request<BuyEventReturn>(axiosConfig);
  }
}

const dateTime2DateStr = (date: Date | string) => {
  if (!date) return '';
  return new Date(date).toISOString().split('T')[0];
};
export default B2bDraftEventService;
