import * as React from 'react';

import Text from 'components/v3/Elements/Texts/Text/Text';
import TextIcon from 'components/v3/Elements/Texts/TextIcon/TextIcon';
import TextButtonWithIcon from 'components/v3/Buttons/TextButtonWithIcon';
import InputRadio from 'components/v3/Forms/InputRadio/InputRadio';
import { BodyS, TitleH2, BodyL, BodyXLBold } from 'styles/v3/variables';
import RoundedCard, { RoundedCardProps } from '../RoundedCard/RoundedCard';

import * as S from './styles';

type Details = {
  text: string,
  highlight: boolean,
};

interface PlanCardProps extends RoundedCardProps {
  id: string;
  title: string;
  name: string;
  price: string | number;
  theme: 'transparent' | 'primary' | 'secondary';
  selected?: string;
  details?: Details[];
  description?: string;
}

const PlanCard: React.FC<PlanCardProps> = ({
  id,
  title,
  name,
  price,
  theme,
  selected,
  details,
  description,
  size,
  children,
  orientation,
  ...props
}) => {
  const isSelected = selected !== '' && id === selected;
  const [seeMoreText, setSeeMoreText] = React.useState<boolean>(false);

  let newTitle: React.ReactElement;
  let newPrice: React.ReactElement;

  switch (size) {
    case 'small':
      newTitle = <BodyS className='card-title'>{title}</BodyS>;
      newPrice = <BodyXLBold className='card-price'>{price}</BodyXLBold>;
      break;
    case 'large':
      newTitle = <TitleH2 className='card-title'>{title}</TitleH2>;
      newPrice = <TitleH2 className='card-price'>{price}</TitleH2>;
      break;
  }

  const onHandleSeeMore = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setSeeMoreText(!seeMoreText);
  };

  return (
    <S.PlanCardWrapper $theme={theme} $size={size} $disabled={!isSelected}>
      <RoundedCard {...props} size={size} orientation={orientation}>
        <InputRadio
          name={name}
          value={id}
          theme={theme === 'primary' ? 'dark' : 'light'}
          id={id}
          checked={id === selected}
          onChange={() => console.log('check')}
        />
        <S.PlanCardContainer
          $theme={theme}
          $size={size}
          $disabled={!isSelected}
        >
          {newTitle}
          {description && (
            <BodyL className='card-description'>{description}</BodyL>
          )}
          {newPrice}
          {!!isSelected && (
            <>
              {children}
              {children && (
                <>
                  <S.DetailsWrapper $show={seeMoreText}>
                    <li>
                      <Text color={theme === 'primary' ? 'grey900' : 'grey50'}>
                        What You’ll Get
                      </Text>
                    </li>
                    {details?.map((i) => (
                      <li key={i.text}>
                        <TextIcon
                          color={theme === 'primary' ? 'grey700' : 'grey200'}
                          icon={i.highlight ? 'Star' : 'Check'}
                        >
                          {i.text}
                        </TextIcon>
                      </li>
                    ))}
                  </S.DetailsWrapper>
                  <TextButtonWithIcon
                    onClick={onHandleSeeMore}
                    rotateIcon={seeMoreText}
                    icon='down'
                    className='plan-card-see-more-button'
                    color={theme === 'primary' ? 'dark' : 'light'}
                    isUpper
                  >
                    {seeMoreText ? 'see less' : 'see more'}
                  </TextButtonWithIcon>
                </>
              )}
            </>
          )}
        </S.PlanCardContainer>
      </RoundedCard>
    </S.PlanCardWrapper>
  );
};

export default PlanCard;
