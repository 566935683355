import React, { FC, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { SingleValue } from 'react-select';
import { useParams, withRouter } from 'react-router-dom';
import { Divider } from 'antd';
import { Controller, useFieldArray, useForm } from 'react-hook-form';

import { sideModalCloseModal } from 'redux/sideModal/actions';

import FilledButton from 'components/v3/Buttons/FilledButton';
import InputText from 'components/v3/Forms/InputText/InputText';
import InputDate from 'components/v3/Forms/InputDate/InputDate';
import InputPhone from 'components/v3/Forms/InputPhone/InputPhone';
import InputFile from 'components/v3/Forms/InputFile/InputFile';
import Select, { OptionsType } from 'components/v3/Forms/Select/Select';
import InputCheckbox from 'components/v3/Forms/InputCheckbox/InputCheckbox';

import ConvertUtil from 'util/ConvertUtil';
import { useUpdateEvent } from 'hooks/v3/event/useUpdateEvent/useUpdateEvent';

import { BodyM, BodyLBold } from 'styles/v3/variables';

import { EventInfoFormData } from './types';
import { useGetEventInfoConfig } from './useGetEventInfoConfig';
import { optionsArray } from './config';
import { useGetFormConfig } from './useGetFormConfig';

import * as S from './styles';

const EventInfo: FC = () => {
  const params: { eventId: string } = useParams();

  const eventId: string = params?.eventId || '';

  const dispatch = useDispatch();

  const {
    eventData,
    logoImgRef,
    headerImgRef,
    imageUpdatedRef,
    isUploading,
    defaultValues,
    selectedConfiguredTime,
    handleImageUpload,
    checkDateValid,
    handleChangeLogo,
    handleChangeHeader,
    unscheduleMatches,
  } = useGetEventInfoConfig();

  const { isLoading, mutateAsync } = useUpdateEvent({ eventId });

  const {
    control,
    watch,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<EventInfoFormData>({
    defaultValues,
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'fields',
  })

  const {
    showTime,
    countOfDays,
    daysArray,
    setShowTime,
    convertToDailyEventsArray,
  } = useGetFormConfig({
    startDate: watch('startDate'),
    endDate: watch('endDate'),
  });

  const handleConfigureTimes = useCallback(() => {
    setShowTime((prevState) => !prevState);
  }, []);

  const handleSubmitForm = async (data: EventInfoFormData) => {
    try {
      let logo = eventData?.logo ?? '';
      let header = eventData?.data?.eventInfo?.header ?? '';

      const dailyEventTimes = convertToDailyEventsArray(data.fields)
      const isDateInvalid = checkDateValid(data.startDate, data.endDate, eventData.eventType ?? '');
      const maxDays = eventData?.eventType === 'TOURNAMENT' ? 7 : 84;

      if (isDateInvalid) {
        setError('startDate', {
          message: `Wrong date! Max difference is ${maxDays} days`,
        });
        setError('endDate', {
          message: `Wrong date! Max difference is ${maxDays} days`,
        });
      } else {
        if (imageUpdatedRef.current.logo) {
          if (logoImgRef.current !== null && logoImgRef.current.files?.length) {
            logo = await handleImageUpload(logoImgRef.current.files);
          }
        }

        if (imageUpdatedRef.current.header) {
          if (
            headerImgRef.current !== null &&
            headerImgRef.current.files?.length
          ) {
            header = await handleImageUpload(headerImgRef.current.files);
          }
        }

        const payload = {
          name: data.name,
          startDate: data.startDate,
          endDate: data.endDate,
          description: data.description,
          dailyEventTimesConfiguredSeparately: showTime,
          logo,
          data: {
            eventInfo: {
              poolName: data.poolName,
              teamIdentifier: data.teamIdentifier,
              header,
              emailContact: data.emailContact,
              phoneContact: data.phoneContact,
            },
          },
        };

        console.log('payload >>', payload);

        await mutateAsync({
          id: eventId,
          data: dailyEventTimes.length
            ? { ...payload, dailyEventTimes }
            : payload,
        });

        if (dailyEventTimes.length) {
          await unscheduleMatches(dailyEventTimes);
        }

        dispatch(sideModalCloseModal());
      }
    } catch (e) {
      console.log('Error updating event', e);
    }
  };

  useEffect(() => {
    remove();
    setShowTime(false);
  }, [watch('startDate'), watch('endDate')])

  useEffect(() => {
    setShowTime(selectedConfiguredTime);
  }, [selectedConfiguredTime]);

  useEffect(() => {
    if (!showTime) {
      remove();

      return;
    }

    const newFields = Array(countOfDays)
      .fill(1)
      .map((_, index) => ({ id: index, value: '' }));

    append(newFields);
  }, [showTime, countOfDays, append, remove])

  return (
    <S.EventInfoWrapper>
      <S.EventInfoFormWrapper>
        <S.EventInfoForm onSubmit={handleSubmit(handleSubmitForm)} noValidate>
          <BodyLBold $color='white'>General info</BodyLBold>
          <fieldset>
            <Controller
              control={control}
              name='name'
              rules={{ required: true }}
              render={({ value, onChange }) => (
                <InputText
                  id='name'
                  label='Event Name'
                  value={value}
                  error={!!errors?.name}
                  errorMessage={errors.name?.message ?? ''}
                  onChange={onChange}
                  placeholder='Event Name'
                  defaultValue={eventData?.name ?? ''}
                  key={`name_${String(eventData?.name)}`}
                  className='general-event-input'
                />
              )}
            />
          </fieldset>
          <S.EventInfoFieldset>
            <Controller
              name='startDate'
              control={control}
              rules={{ required: true }}
              defaultValue={
                eventData?.startDate
                  ? new Date(eventData?.startDate as Date)
                      .toISOString()
                      .split('T')[0]
                  : new Date().toISOString().split('T')[0]
              }
              render={({ value, onChange }) => (
                <InputDate
                  value={value}
                  id='startDate'
                  placeholder='Start Date'
                  error={!!errors?.startDate}
                  errorMessage={errors.startDate?.message ?? ''}
                  key={`startDate_${String(eventData?.startDate)}`}
                  onChange={onChange}
                  className='general-event-input-date'
                />
              )}
            />
            <Controller
              name='endDate'
              control={control}
              rules={{ required: true }}
              defaultValue={
                eventData?.endDate
                  ? new Date(eventData?.endDate as Date)
                      .toISOString()
                      .split('T')[0]
                  : new Date().toISOString().split('T')[0]
              }
              render={({ value, onChange }) => (
                <InputDate
                  id='endDate'
                  value={value}
                  error={!!errors?.endDate}
                  errorMessage={errors.endDate?.message ?? ''}
                  placeholder='End Date'
                  key={`endDate_${String(eventData?.endDate)}`}
                  onChange={onChange}
                  className='general-event-input-date'
                />
              )}
            />
          </S.EventInfoFieldset>
          <div style={{ display: 'flex', alignItems: 'center', columnGap: 10 }}>
            <InputCheckbox
              id='showEndStartTime'
              name='showEndStartCheckbox'
              checked={showTime}
              onChange={handleConfigureTimes}
            />
            <BodyM>Configure start and end time per day</BodyM>
          </div>
          <div>
            {fields.map((fieldItem, index) => (
              <div
                key={fieldItem.id}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: 10,
                  marginBottom: 10,
                }}
              >
                <BodyM>Day: {daysArray[index]}</BodyM>
                <Controller
                  name={`fields[${index}].${daysArray[index]}`}
                  control={control}
                  render={(props) => (
                    <S.StyledTimePicker
                      use12Hours
                      value={props.value}
                      onChange={props.onChange}
                      format='h:mm a'
                      defaultValue={fieldItem[daysArray[index]]}
                      minuteStep={60}
                      showNow={false}
                    />
                  )}
                />
              </div>
            ))}
          </div>
          <fieldset>
            <Controller
              control={control}
              name='description'
              render={({ value, onChange }) => (
                <InputText
                  id='description'
                  value={value}
                  label='Description'
                  placeholder='Add a short description for your event'
                  key={`description_${String(eventData?.description)}`}
                  defaultValue={eventData?.description}
                  onChange={onChange}
                />
              )}
            />
          </fieldset>
          <fieldset>
            <Controller
              name='poolName'
              control={control}
              rules={{ required: true }}
              defaultValue={eventData?.data?.eventInfo?.poolName ?? ''}
              render={({ onChange }) => (
                <Select
                  placeholder='Pool Name Format'
                  name='poolName'
                  error={!!errors?.poolName}
                  errorMessage={errors.poolName?.message ?? ''}
                  key={`poolName_${String(
                    eventData?.data?.eventInfo?.poolName
                  )}`}
                  defaultValue={
                    eventData?.data?.eventInfo?.poolName
                      ? {
                          value: eventData?.data.eventInfo.poolName,
                          label: eventData?.data?.eventInfo.poolName,
                        }
                      : null
                  }
                  options={optionsArray}
                  onChange={(option) =>
                    onChange((option as SingleValue<OptionsType>)?.value ?? '')
                  }
                />
              )}
            />
          </fieldset>
          <fieldset>
            <Controller
              name='teamIdentifier'
              control={control}
              rules={{ required: true }}
              defaultValue={eventData?.data?.eventInfo?.teamIdentifier ?? ''}
              render={({ onChange }) => (
                <Select
                  placeholder='Team Identifier Format'
                  name='teamIdentifier'
                  key={`teamIdentifier_${String(
                    eventData?.data?.eventInfo?.teamIdentifier
                  )}`}
                  error={!!errors?.teamIdentifier}
                  errorMessage={errors.teamIdentifier?.message ?? ''}
                  defaultValue={
                    eventData?.data?.eventInfo?.teamIdentifier
                      ? {
                          value: eventData?.data?.eventInfo.teamIdentifier,
                          label: eventData?.data?.eventInfo.teamIdentifier,
                        }
                      : null
                  }
                  options={optionsArray}
                  onChange={(option) =>
                    onChange((option as SingleValue<OptionsType>)?.value ?? '')
                  }
                />
              )}
            />
          </fieldset>
          <Divider style={{ margin: 0 }} />
          <BodyLBold $color='white'>Contact Info</BodyLBold>
          <fieldset>
            <Controller
              name='emailContact'
              control={control}
              rules={{ required: true }}
              defaultValue={eventData?.data?.eventInfo?.emailContact ?? ''}
              render={({ value, onChange }) => (
                <InputText
                  id='emailContact'
                  label='Email'
                  value={value}
                  placeholder='Email'
                  error={!!errors?.emailContact}
                  errorMessage='Email is required'
                  key={`name_${String(
                    eventData?.data?.eventInfo?.emailContact
                  )}`}
                  className='general-event-input'
                  onChange={onChange}
                />
              )}
            />
          </fieldset>
          <fieldset>
            <Controller
              name='phoneContact'
              control={control}
              rules={{ required: true }}
              defaultValue={eventData?.data?.eventInfo?.phoneContact ?? ''}
              render={({ value, onChange }) => (
                <InputPhone
                  value={value}
                  id='phoneContact'
                  label='Phone Number'
                  defaultValue={
                    eventData?.data?.eventInfo?.phoneContact ?? ''
                  }
                  placeholder='Phone Number'
                  error={!!errors.phoneContact}
                  errorMessage='Phone number is required'
                  key={`name_${String(
                    eventData?.data?.eventInfo?.phoneContact
                  )}`}
                  onChange={onChange}
                  className='general-event-input'
                />
              )}
            />
          </fieldset>
          <Divider style={{ margin: 0 }} />
          <BodyLBold $color='white'>Events Visual identify</BodyLBold>
          <BodyM className='field-description' $color='white'>
            Upload a Logo for your event. The logo must be{' '}
            <span>512px by 512px</span>
          </BodyM>
          <fieldset>
            <S.EventInfoSummaryHeader>
              <InputFile
                format='circle'
                key={`logoImg_${String(eventData?.logo)}`}
                ref={logoImgRef}
                previewUrl={
                  eventData?.logo !== ''
                    ? ConvertUtil.getMinioUrl(eventData?.logo)
                    : undefined
                }
                id='logoImg'
                text='Add Logo'
                onChange={handleChangeLogo}
              />
            </S.EventInfoSummaryHeader>
          </fieldset>
          <BodyM className='field-description' $color='white'>
            Upload a Header for your event. The header will show up on your
            event page. It must be <span>1440px by 250px</span>
          </BodyM>
          <fieldset>
            <S.EventInfoSummaryHeader>
              <InputFile
                format='square'
                ref={headerImgRef}
                key={`header_${String(eventData?.data?.eventInfo?.header)}`}
                previewUrl={
                  eventData?.data?.eventInfo?.header
                    ? ConvertUtil.getMinioUrl(eventData.data.eventInfo.header)
                    : undefined
                }
                id='headerImg'
                text='Add Header'
                onChange={handleChangeHeader}
              />
            </S.EventInfoSummaryHeader>
          </fieldset>
          <FilledButton
            isUpper
            type='submit'
            color='primary'
            isLoading={isLoading || isUploading}
          >
            Save {isUploading && '...'}
          </FilledButton>
        </S.EventInfoForm>
      </S.EventInfoFormWrapper>
    </S.EventInfoWrapper>
  );
};

export default withRouter(EventInfo);
