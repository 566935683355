import { VenueView } from "admin/models/event/Venue";
import { AxiosRequestConfig } from "axios";
import { ConfigApi } from "services/config.api";
import APIServicesV3 from "services/util/ApiServicesV3";
import ServiceResponseV3 from "services/util/ServiceResponseV3";

class VenueService {
  public static async getVenueById(
    id?: string
  ): Promise<ServiceResponseV3<VenueView | undefined>> {
    if (!id)
      return ServiceResponseV3.success<VenueView | undefined>(undefined, '');

    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getNoAccountConfig(),
      url: `venues/${id}`,
      method: 'GET',
    };
    return APIServicesV3.request<VenueView>(axiosConfig);
  }
}

export default VenueService;
