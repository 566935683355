import Keycloak from 'keycloak-js';
import moment from 'moment';
import { message } from 'antd';
import * as React from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import {
  b2bDraftEventSetEventType,
  b2bDraftEventUpdateEventInfo,
} from 'redux/v3/b2bDraftEvent/actions';
import { ApplicationState } from 'redux/store';
import B2bDraftEventService from 'services/v3/B2bDraftEvent/B2bDraftEventService';
import UploadService from 'services/upload/UploadService';

import TextButtonWithIcon from 'components/v3/Buttons/TextButtonWithIcon';
import FilledButtonWithIcon from 'components/v3/Buttons/FilledButtonWithIcon';
import PlanCard from 'components/v3/Cards/PlanCard/PlanCard';
import {
  BodyL,
  BodyM,
  BodyMBold,
  BodyS,
  BodyXL,
  TitleH1,
  TitleH4,
} from 'styles/v3/variables';
import MapImage from 'assets/imgs/general-event/map.png';
import Trophy1 from 'assets/imgs/trophies/big-trophy-1.png';
import Trophy2 from 'assets/imgs/trophies/big-trophy-2.png';

import InputText from 'components/v3/Forms/InputText/InputText';
import InputDate from 'components/v3/Forms/InputDate/InputDate';
import RoundedCard from 'components/v3/Cards/RoundedCard/RoundedCard';
import InputFile from 'components/v3/Forms/InputFile/InputFile';
import Loading from 'components/v3/Loading/Loading';
import * as S from './styles';

interface GeneralEventProps {
  children?: React.ReactNode;
  auth: Keycloak.KeycloakInstance;
}
const GeneralEvent: React.FunctionComponent = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { auth } = props as GeneralEventProps;

  const logoImgRef = React.useRef<HTMLInputElement>(null);

  const userEmail = ((auth?.tokenParsed as any)?.email as string) ?? '';

  const [isLoadingPayment, setIsLoadingPayment] = useState(false);

  const {
    selectedEvent,
    eventInfo,
    events,
  } = useSelector((state: ApplicationState) => state.b2bDraftEvent.data);

  function handlePreviousStep() {
    history.push('/v3/draft-event/plan-selection');
  }

  const handleSelectEvent = (eventId: string) => {
    dispatch(b2bDraftEventSetEventType(eventId));
  };

  const handleEventInfoChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(
      b2bDraftEventUpdateEventInfo({
        [event.target.name]: event.target.value,
      })
    );
  };
  const handleInputClear = (field: string) => {
    dispatch(
      b2bDraftEventUpdateEventInfo({
        [field]: '',
      })
    );
  };

  const handleBuyEvent = async () => {
    try {
      const maxDays = selectedEvent?.type === 'B2B_EVENT_TOURNAMENT' ? 7 : 84;
      const diff = moment
        .duration(moment(eventInfo.endDate).diff(moment(eventInfo.startDate)))
        .asDays();
      if (diff < 0 || diff > maxDays) {
          message.error({
            content: `Wrong date! Max difference is ${maxDays} days`,
            duration: 5,
          });
      } else {
          setIsLoadingPayment(true);

          const logoImg = await handleUploadLogo();
          const paymentRequest = await B2bDraftEventService.buyEvent({
            eventData: {
              selectedEvent,
              eventInfo: {
                ...eventInfo,
                logoImg,
              },
              events,
            },
            userEmail,
          });

          const {
            data: { paymentUrl },
          } = paymentRequest;

          if (paymentUrl) {
            // redirect to stripe checkout
            window.location.href = paymentUrl;
          }
          setIsLoadingPayment(false);
      }
       
    } catch (e) {
      setIsLoadingPayment(false);
      message.error({
        content: 'Something went wrong',
        duration: 5,
      });
    }
  };

  const handleUploadLogo = async () => {
    const files = logoImgRef.current?.files;

    let imageId = '';

    if (files && files.length > 0) {
      const file = files[0];
      try {
        await Promise.all([
          UploadService.upload(
            {
              file,
              prefix: 'event',
              public: true,
            },
            true
          ).then((response) => {
            if (response.success) {
              dispatch(
                b2bDraftEventUpdateEventInfo({
                  logoImg: response.data.id,
                })
              );
              imageId = response.data.id;
            }
          }),
        ]);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error('error uploading file', e);
      }
    }

    return imageId;
  };

  if (isLoadingPayment) {
    return <Loading />;
  }

  return (
    <S.GeneralEventWrapper>
      <S.GeneralEventFormWrapper>
        <TextButtonWithIcon
          color='primary'
          align='flex-end'
          reverse
          icon='back'
          onClick={handlePreviousStep}
        >
          Go back
        </TextButtonWithIcon>

        <TitleH1 $color='white' $spanColor='brandPrimary'>
          Tell us more about <span>the event</span>
        </TitleH1>

        <S.GeneralEventForm>
          <S.GeneralEventFieldset>
            <legend>
              <BodyXL $color='brandPrimary' $isUpper>
                General {selectedEvent?.title ?? ''}
              </BodyXL>
            </legend>
           {events?.map((ev) => (
              <PlanCard
                key={ev.id}
                id={ev.id}
                size='small'
                theme={ev.type === 'B2B_EVENT_LEAGUE' ? 'secondary' : 'primary'}
                name='plans'
                selected={selectedEvent?.id}
                onClick={() => handleSelectEvent(ev.id)}
                title={ev.title}
                price='$500'
                orientation='column'
              />
            ))}
          </S.GeneralEventFieldset>

          <fieldset>
            <InputText
              id='name'
              label='Event Name'
              placeholder='Event Name'
              defaultValue={eventInfo.name}
              onChange={handleEventInfoChange}
              onClearInput={(id) => handleInputClear(id)}
              containerClassName='general-event-input'
            />
            <InputText
              id='location'
              label='Location'
              placeholder='Location'
              defaultValue={eventInfo.location}
              onChange={handleEventInfoChange}
              onClearInput={(id) => handleInputClear(id)}
              containerClassName='general-event-input'
            />
          </fieldset>

          <S.GeneralEventFieldset className='style-grid'>
            <InputDate
              id='startDate'
              placeholder='Start Date'
              defaultValue={
                eventInfo.startDate
                  ? new Date(eventInfo.startDate).toISOString().split('T')[0]
                  : new Date().toISOString().split('T')[0]
              }
              onChange={handleEventInfoChange}
            />
            <InputDate
              id='endDate'
              placeholder='End Date'
              defaultValue={
                eventInfo.endDate
                  ? new Date(eventInfo.endDate).toISOString().split('T')[0]
                  : new Date().toISOString().split('T')[0]
              }
              onChange={handleEventInfoChange}
            />
          </S.GeneralEventFieldset>

          <fieldset>
            <InputText
              id='description'
              label='Description'
              placeholder='Add a short description for your event'
              onChange={handleEventInfoChange}
              onClearInput={(id) => handleInputClear(id)}
              defaultValue={eventInfo.description}
            />
          </fieldset>

          <fieldset>
            <legend>
              <BodyXL $color='brandPrimary' $isUpper>
                Game Settings
              </BodyXL>
            </legend>
            <InputText
              id='timeSlots'
              placeholder='Time Slots'
              label='Time Slots'
              defaultValue={eventInfo.timeSlots}
              onChange={handleEventInfoChange}
              onClearInput={(id) => handleInputClear(id)}
            />
            <BodyS $color='grey400' className='general-event-info'>
              In what time slots will you use to organize your event?
              <br /> E.g.: 30min, 50min 1hour.
            </BodyS>
            <InputText
              id='courtCount'
              placeholder='Estimated number of courts'
              label='Estimated number of courts'
              onChange={handleEventInfoChange}
              type='number'
              min={0}
              defaultValue={eventInfo.courtCount}
            />
          </fieldset>
        </S.GeneralEventForm>
      </S.GeneralEventFormWrapper>

      <S.GeneralEventSummary>
        <RoundedCard orientation='column' size='large' bgColor='grey950'>
          <S.GeneralEventSummaryHeader>
            <InputFile ref={logoImgRef} id='logoImg' text='Add Logo' />
            <TitleH4 $isUpper className='summary-title'>
              {eventInfo.name ? eventInfo.name : 'Event Name'}
            </TitleH4>
            <BodyM $color='grey400'>
              {eventInfo.description ? eventInfo.description : 'Description'}
            </BodyM>
          </S.GeneralEventSummaryHeader>

          <BodyL $isUpper className='summary-subtitle'>
            General Info
          </BodyL>
          <S.GeneralEventSummaryBody>
            <BodyM $color='grey300'>Starts</BodyM>
            <BodyMBold>
              {moment(eventInfo.startDate).format('MMMM DD, yyyy')}
            </BodyMBold>
          </S.GeneralEventSummaryBody>
          <S.GeneralEventSummaryBody>
            <BodyM $color='grey300'>Ends</BodyM>
            <BodyMBold>
              {moment(eventInfo.endDate).format('MMMM DD, yyyy')}
            </BodyMBold>
          </S.GeneralEventSummaryBody>
          <S.GeneralEventSummaryMapWrapper>
            <img src={MapImage} alt='Event Map' />
            <BodyM>{eventInfo.location ?? ''}</BodyM>
          </S.GeneralEventSummaryMapWrapper>

          <BodyL $isUpper className='summary-subtitle'>
            Game Settings
          </BodyL>
          <S.GeneralEventSummaryBody>
            <BodyM $color='grey300'>Time Slots</BodyM>
            <BodyMBold>{eventInfo.timeSlots ?? ''}</BodyMBold>
          </S.GeneralEventSummaryBody>
          <S.GeneralEventSummaryBody>
            <BodyM $color='grey300'>Number of Courts</BodyM>
            <BodyMBold>
              {eventInfo.courtCount || '0'}{' '}
              {eventInfo.courtCount > 1 ? 'courts' : 'court'}
            </BodyMBold>
          </S.GeneralEventSummaryBody>

          <RoundedCard
            orientation='row'
            size='large'
            bgColor='grey900'
            className='general-event-selected'
          >
            <img
              className='trophy-image'
              alt='Trophy'
              src={
                selectedEvent?.type === 'B2B_EVENT_LEAGUE' ? Trophy2 : Trophy1
              }
            />
            <S.GeneralEventSummaryEventSelected>
              <BodyM>{selectedEvent?.title ?? ''}</BodyM>
              <TitleH4 $color='brandPrimary'>
                ${selectedEvent?.price ? selectedEvent.price / 100 : ''}
              </TitleH4>
            </S.GeneralEventSummaryEventSelected>
          </RoundedCard>

          <FilledButtonWithIcon
            onClick={handleBuyEvent}
            color='primary'
            isUpper
          >
            Continue to Payment
          </FilledButtonWithIcon>
        </RoundedCard>
      </S.GeneralEventSummary>
    </S.GeneralEventWrapper>
  );
};

export default withRouter(GeneralEvent);
