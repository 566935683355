import Keycloak from 'keycloak-js';
import * as React from 'react';
import { Plus } from '@icon-park/react';
import { withRouter, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { ApplicationState } from 'redux/store';
import { TitleH2 } from 'styles/v3/variables';
import TabBar from 'components/v3/Elements/TabBar/TabBar';
import EventCard from 'components/v3/Cards/EventCard/EventCard';
import B2bEventService from 'services/v3/B2bEvent/B2bEventService';
import { b2bSetupEventFetchEventsSuccess } from 'redux/v3/b2bSetupEvent/actions';
import { b2bDraftEventClearEvent } from 'redux/v3/b2bDraftEvent/actions';

import * as S from './styles';

const tabItems = [
  {
    name: 'my events',
    link: '/v3/events?tab=my-events',
    path: '/v3/events?tab=my-events',
  },
  {
    name: 'created by me',
    link: '/v3/setup-event/my-events',
    path: '/v3/setup-event/my-events',
  },
  {
    name: 'discover events',
    link: '/v3/events?tab=discover-events',
    path: '/v3/events?tab=discover-events',
  },
];

interface MyEventsProps {
  children?: React.ReactNode;
  auth: Keycloak.KeycloakInstance;
}
const MyEvents: React.FunctionComponent = (props) => {
  const { auth } = props as MyEventsProps;
  const email = ((auth?.tokenParsed as any)?.email as string) ?? '';
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const dispatch = useDispatch();

  const { content: storedEvents } = useSelector(
    (state: ApplicationState) => state.b2bSetupEvent.data
  );

  const handleLoadEvents = async () => {
    setIsLoading(true);
    try {
      const { data: fetchedEvents } = await B2bEventService.listEvents();
      if (fetchedEvents.content.length !== storedEvents.length) {
        dispatch(b2bSetupEventFetchEventsSuccess(fetchedEvents));
      }
      setIsLoading(false);
    } catch (e) {
      console.error(e);
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    handleLoadEvents();
  }, [storedEvents]);

  const handleNewEvent = () => {
    dispatch(b2bDraftEventClearEvent());
  };

  return (
    <>
      <TitleH2>Events</TitleH2>
      <S.EventsHeader>
        <TabBar items={tabItems} />
        <Link to='/v3/draft-event/plan-selection'>
          <S.NewEventButton onClick={handleNewEvent}>
            New Event <Plus />
          </S.NewEventButton>
        </Link>
      </S.EventsHeader>

      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <S.EventsList>
          {storedEvents.map((i) => {
            const eventStatus = i.draft ? 'draft' : 'published';
            const published = eventStatus === 'published' ? '/published' : '';
            const link = `/v3/setup-event/my-events/${i.id}${published}`;
            return (
              <EventCard
                key={i.id}
                eventStatus={eventStatus}
                orientation='column'
                size='small'
                title={i.name}
                description={i.description}
                startDate={i.startDate}
                finalDate={i.endDate}
                id={i.id}
                logo={i.logo}
                divisions={i.ageDivisions}
                link={link}
              />
            );
          })}
        </S.EventsList>
      )}
    </>
  );
};

export default withRouter(MyEvents);
