import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Down, Up } from '@icon-park/react';
import { UnmountClosed } from 'react-collapse';

import ConvertUtil from 'util/ConvertUtil';
import ClubPlaceholder from 'assets/imgs/club/club_placeholder.png';
import { NewAgeDivision } from 'admin/models/AgeDivision';

import { BodyS, TitleH4 } from 'styles/v3/variables';
import OutlinedButtonWithIcon from 'components/v3/Buttons/OutlinedButtonWithIcon';
import DivisionCard from 'components/v3/Cards/DivisionCard';
import { B2bEventStatus } from 'models/v3/Event/Event';
import { RoundedCardProps } from '../RoundedCard/RoundedCard';

import {
  EventCardWrapper,
  EventContentWrapper,
  EventIcon,
  EventContent,
  EventDateOccurence,
  EventDates,
  IsTodayEvent,
  ApplicationsResumeBtn,
  EventBtnActions,
  EventStatus,
  EventDivisionsContainer,
} from './styles';

interface EventCardProps extends RoundedCardProps {
  title: string;
  description: string;
  divisions?: NewAgeDivision[];
  eventStatus: B2bEventStatus;
  id: string;
  link: string;
  logo?: string;
  startDate: Date;
  finalDate: Date;
  actionText?: string;
}

const EventCard: React.FC<EventCardProps> = ({
  title,
  description,
  size,
  orientation,
  logo,
  eventStatus,
  id,
  link,
  startDate,
  finalDate,
  divisions = null,
  actionText = 'Manage',
  ...props
}) => {
  const history = useHistory();
  const [collapse, setCollapse] = useState<boolean>(false);

  const startAt = new Date(startDate).toLocaleDateString('en-US', {
    month: 'short',
    day: '2-digit',
    year: 'numeric',
  });

  const endAt = new Date(finalDate).toLocaleDateString('en-US', {
    month: 'short',
    day: '2-digit',
    year: 'numeric',
  });

  const handleGoTopublished = () => {
    if (eventStatus === 'published') {
      history.push(link);
    }
  };

  const startEvent = new Date(startDate).getTime();
  const today = new Date().getTime();
  const finalEvent = new Date(finalDate).getTime();

  const isHappening = today >= startEvent && today <= finalEvent;

  return (
    <EventCardWrapper
      isHappening={isHappening}
      onClick={handleGoTopublished}
      eventStatus={eventStatus}
    >
      <EventContentWrapper>
        <EventIcon>
          {logo ? (
            <img src={ConvertUtil.getMinioUrl(logo)} alt='Event Logo' />
          ) : (
            <img src={ClubPlaceholder} alt='Event Placeholder' />
          )}
        </EventIcon>

        <EventContent>
          <EventDates>
            {isHappening && <IsTodayEvent>Today</IsTodayEvent>}
            <EventDateOccurence>
              {startAt} - {endAt}
            </EventDateOccurence>
          </EventDates>

          <EventStatus eventStatus={eventStatus}>{eventStatus}</EventStatus>

          <TitleH4>{title}</TitleH4>
          <BodyS $color='grey300'>{description}</BodyS>
        </EventContent>

        <EventBtnActions>
          <Link to={link}>
            <OutlinedButtonWithIcon color='dark-white' isBold={false} isUpper>
              {actionText}
            </OutlinedButtonWithIcon>
          </Link>
        </EventBtnActions>
      </EventContentWrapper>
      {divisions && (
        <>
          <UnmountClosed isOpened={collapse}>
            <EventDivisionsContainer>
              {divisions.map((division) => (
                <DivisionCard
                  key={`division_${division.id}`}
                  division={division}
                />
              ))}
            </EventDivisionsContainer>
          </UnmountClosed>

          <ApplicationsResumeBtn onClick={() => setCollapse(!collapse)}>
            Applications Resume{' '}
            {collapse ? <Up size={24} /> : <Down size={24} />}
          </ApplicationsResumeBtn>
        </>
      )}
    </EventCardWrapper>
  );
};

export default EventCard;
