import { useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import moment from 'moment';

import { ApplicationState } from 'redux/store';
import { MatchStatus } from 'models/Match/MatchModel';
import UploadService from 'services/upload/UploadService';

import { notification } from 'components/v3/Notification/notification';

import { useGetMathesEvent } from 'hooks/v3/matches/useGetMatchesEvent/useGetMatchesEvent';
import { useUpdateMatchesEvent } from 'hooks/v3/matches/useUpdateMatchesEvent/useUpdateMatchesEvent';

export const useGetEventInfoConfig = () => {
  const { eventId } = useParams<{ eventId: string }>();

  const logoImgRef = useRef<HTMLInputElement>(null);
  const headerImgRef = useRef<HTMLInputElement>(null);
  const imageUpdatedRef = useRef({
    logo: false,
    header: false,
  });

  const { eventData } = useSelector(
    (state: ApplicationState) => state.b2bSetupEvent
  );
  const { data: matches } = useGetMathesEvent(eventId ?? '');
  const { mutateAsync: updateSchedule } = useUpdateMatchesEvent();

  const [isUploading, setIsUploading] = useState<boolean>(false);

  const defaultValues = {
    name: eventData?.name ?? '',
    endDate: eventData?.endDate ? (eventData?.endDate as Date) : '',
    startDate: eventData?.startDate ? (eventData?.startDate as Date) : '',
    description: eventData?.description ?? '',
    logo: eventData?.logo ?? '',
    poolName: eventData?.data?.eventInfo?.poolName ?? '',
    teamIdentifier: eventData?.data?.eventInfo?.teamIdentifier ?? '',
    header: eventData.data?.eventInfo?.header ?? '',
    emailContact: eventData.data?.eventInfo?.emailContact ?? '',
    phoneContact: eventData.data?.eventInfo?.phoneContact ?? '',
    fields:
      eventData?.dailyEventTimes?.map((time) => ({
        [time?.day ?? 'range']: [
          moment(`${time?.day} ${time?.startTime}`, 'YYYY-MM-DD HH:mm:ss'),
          moment(`${time?.day} ${time?.endTime}`, 'YYYY-MM-DD HH:mm:ss'),
        ],
      })).reverse() ?? [],
  };

  const selectedConfiguredTime = useMemo(() => {
    return eventData?.dailyEventTimesConfiguredSeparately ?? false;
  }, [eventData]);

  const handleImageUpload = async (files: FileList) => {
    let imageId = '';

    if (files && files.length > 0) {
      const file = files[0];

      setIsUploading(true);

      try {
        await Promise.all([
          UploadService.upload(
            {
              file,
              prefix: 'event',
              public: true,
            },
            true
          ).then((response) => {
            if (response.success) {
              imageId = response.data.id;
            }
          }),
        ]);
      } catch (e) {
        console.error('error uploading file', e);
      }
    }

    setIsUploading(false);

    return imageId;
  };

  const handleChangeLogo = () => {
    imageUpdatedRef.current.logo = true;
  };

  const handleChangeHeader = () => {
    imageUpdatedRef.current.header = true;
  };

  const checkDateValid = (
    startDate: string | Date,
    endDate: string | Date,
    eventType: string
  ) => {
    const maxDays = eventType === 'TOURNAMENT' ? 7 : 84;
    const diff = moment
      .duration(moment(endDate).diff(moment(startDate)))
      .asDays();

    return diff < 0 || diff > maxDays;
  };

  const unscheduleMatches = async (
    dailyEventTimes: (
      | { day?: string; startTime?: string; endTime?: string }
      | undefined
    )[]
  ) => {
    try {
      const scheduled = matches.filter((match) => match.status === MatchStatus.SCHEDULED);

      if (scheduled.length > 0) {
        let matchesForUnschedule: any[] = scheduled?.filter((match) => {
          const scheduledMatch = dailyEventTimes?.find(
            (dailyEventTime) => {
              const dayTime = moment(match?.date ?? '').format('HH:mm:ss');

              return moment(match?.date ?? '').format('YYYY-MM-DD') === dailyEventTime?.day &&
              (moment(dayTime, 'HH:mm:ss').isBefore(moment(dailyEventTime?.startTime, 'HH:mm:ss'))
              || (moment(dayTime, 'HH:mm:ss').isAfter(moment(dailyEventTime?.endTime, 'HH:mm:ss')))) 
            }
          );

          return scheduledMatch;
        });

        matchesForUnschedule = matchesForUnschedule.map((element) => ({
          matchId: element.matchId,
          unschedule: true,
        }));

        if (matchesForUnschedule.length > 0) {
          await updateSchedule(matchesForUnschedule);

          notification.success({
            message: 'Some matches unscheduled!',
          });
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  return {
    eventData,
    logoImgRef,
    headerImgRef,
    imageUpdatedRef,
    isUploading,
    defaultValues,
    selectedConfiguredTime,
    setIsUploading,
    handleImageUpload,
    checkDateValid,
    handleChangeLogo,
    handleChangeHeader,
    unscheduleMatches,
  };
};
