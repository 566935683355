import React from 'react';

import { Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { BodyXL } from 'styles/v3/variables';

import { useGetProfiles } from 'hooks/v3/users/useGetProfiles/useGetProfiles';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'redux/store';
import ConvertUtil from 'util/ConvertUtil';
import UserType from 'models/User/UserTypeModel';
import { SettingTwo } from '@icon-park/react';
import { useHistory } from 'react-router-dom';
import { formatUserName } from 'util/formatUserName';
import { onClose, onOpen } from 'redux/v3/profileModal/actions';
import useScreenType from 'hooks/useScreenType';
import { AccountTag } from './components/AccountTag/AccountTag';
import { AccountAccordion } from './components/AccountAccordion/AccountAccordion';

import * as S from './styles';

export const AccountModal = () => {
  useGetProfiles();

  const history = useHistory();
  const dispatch = useDispatch();
  const { isMobile } = useScreenType();

  const isOpenProfileModal = useSelector(
    (state: ApplicationState) => state.profileModal.isOpen
  );

  const currentUser = useSelector(
    (state: ApplicationState) => state.currentUser.data
  );

  const isMember = currentUser?.membershipType === 'OFFICIAL';

  const types = [
    currentUser?.type,
    ...(Array.isArray(currentUser?.types) ? currentUser.types : []),
  ].filter(Boolean) as Array<UserType>;

  const handleGoToUpgradeMembership = () => {
    history.push('/v3/profile/upgrade-membership');
  };

  const handleRedirect = () => {
    dispatch(onClose());
    history.push(`/v3/user/${currentUser.id}/info`);
  };

  return (
    <S.StyledModal
      mask={false}
      maskClosable
      footer={false}
      closable={false}
      centered={isMobile}
      maskStyle={{ zIndex: 0 }}
      visible={isOpenProfileModal}
      onOk={() => dispatch(onOpen())}
      onCancel={() => dispatch(onClose())}
      width={!isMobile ? '460px' : '100vw'}
      style={
        !isMobile ? { top: 100, right: 0, position: 'absolute' } : undefined
      }
    >
      <S.ModalWrapper>
        <S.ButtonWrapper>
          <S.SettingsButton onClick={handleRedirect}>
            <SettingTwo size='24px' />
          </S.SettingsButton>
        </S.ButtonWrapper>
        <S.HeaderWrapper>
          <Avatar
            size={118}
            icon={<UserOutlined />}
            src={ConvertUtil.getMinioUrl(currentUser.photo)}
          />
          <BodyXL $color='grey50' $fontWeight='700'>
            {formatUserName({
              lastName: currentUser.lastName,
              firstName: currentUser.firstName,
              middleName: currentUser.middleName,
            })}
          </BodyXL>
          <S.TagsWrapper>
            {types?.map((type) => (
              <AccountTag key={type} type={type} />
            ))}
          </S.TagsWrapper>
        </S.HeaderWrapper>
        {!isMember && (
          <S.UpgradeButton onClick={handleGoToUpgradeMembership}>
            Upgrade Membership
          </S.UpgradeButton>
        )}
        <AccountAccordion />
      </S.ModalWrapper>
    </S.StyledModal>
  );
};
