import React from 'react';
import { useInfiniteQuery } from '@tanstack/react-query';
import { Plus, More } from '@icon-park/react';

import EventService from 'services/v3/Event/EventService';

import OutlinedButtonWithIcon from 'components/v3/Buttons/OutlinedButtonWithIcon';
import Loading from 'components/v3/Loading/Loading';

import EventCard from 'components/v3/Cards/EventCard/EventCard';
import * as S from './styles';

export const DiscoverEvents = () => {
  const fetchEvents = async ({ pageParam = 0 }) => {
    const res = await EventService.getActiveEventsPaged(10, pageParam);
    return res.data;
  };

  const {
    data: content,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    status,
  } = useInfiniteQuery({
    queryKey: ['discover-events'],
    queryFn: fetchEvents,
    getNextPageParam: (lastPage) =>
      lastPage.hasNextPage ? lastPage.page + 1 : undefined,
  });

  return (
    <S.EventsList>
      {status === 'loading' && <Loading />}
      {content?.pages.map((page) => {
        return page.content.map((item) => {
          const eventStatus = item.draft ? 'draft' : 'published';
          return (
            <EventCard
              size='small'
              key={item.id}
              id={item.id}
              logo={item.logo}
              title={item.name}
              orientation='column'
              actionText='See event'
              eventStatus={eventStatus}
              description={item.description}
              startDate={item.startDate}
              finalDate={item.endDate}
              link={`/v3/event/${item.id}`}
            />
          );
        });
      })}

      {hasNextPage && (
        <S.ButtonLoadMore>
          <OutlinedButtonWithIcon
            reverse
            customIcon={isFetchingNextPage ? <More /> : <Plus />}
            disabled={!hasNextPage}
            onClick={() => fetchNextPage()}
          >
            Load More Events
          </OutlinedButtonWithIcon>
        </S.ButtonLoadMore>
      )}
    </S.EventsList>
  );
};
