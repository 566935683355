import React, { FC, useRef, useState, useMemo, useCallback } from 'react';
import { RightSmall, LeftSmall } from '@icon-park/react';
import AliceCarousel from 'react-alice-carousel';
import { useHistory } from 'react-router-dom';
import { UserOutlined } from '@ant-design/icons';

import { EventListView } from 'admin/models/event/Event';
import UserType from 'models/User/UserTypeModel';

import ConvertUtil from 'util/ConvertUtil';
import { formatUserName } from 'util/formatUserName';
import { useProfile } from 'hooks/v3/profile/useProfile';
import { useGetJoinedEvents } from 'hooks/v3/event/useGetJoinedEvents/useGetJoinedEvents';
import { useGetDiscoverEvents } from 'hooks/v3/event/useGetDiscoverEvents/useGetDiscoverEvents';

import OutlinedButton from 'components/v3/Buttons/OutlinedButton';
import EventCard from 'components/v3/Cards/EventCardHome';

import { TitleH2, TitleH3, TitleH4, BodyM, BodyMBold } from 'styles/v3/variables';

import {
  UserXPInformation,
  UserTypesInformation,
} from './components/UserXPInformation';
import { AccountStatus } from './components/AccountStatus';
import { EVENTS_CAROUSEL_RESPONSIVE, MAX_OTHER_EVENT_SLIDES } from './configs';

import * as S from './styles'

import 'react-alice-carousel/lib/alice-carousel.css';

const Home: FC = () => {
  const history = useHistory();

  const carouselRefEvents = useRef<AliceCarousel>(null);

  const [carouselItem, setItemCarousel] = useState(1);

  const { currentUser, missingInformation } = useProfile()

  const { data: discoverEventsResponse } = useGetDiscoverEvents(MAX_OTHER_EVENT_SLIDES, 0);
  const { data: joinedEventsResponse } = useGetJoinedEvents(1);

  const joinedEventsTotal = useMemo(() => joinedEventsResponse?.data.total ?? 0, [joinedEventsResponse]);
  const discoverEventsTotal = useMemo(() => discoverEventsResponse?.data.total ?? 0, [discoverEventsResponse]);

  const joinedEvent = useMemo(() => {
    if (joinedEventsResponse) {
      return joinedEventsResponse.data.content[0];
    }

    return null;
  }, [joinedEventsResponse]);

  const discoverEvents = useMemo<Array<Maybe<EventListView>>>(() => {
    if (!discoverEventsResponse) {
      return [];
    }

    if (discoverEventsTotal > MAX_OTHER_EVENT_SLIDES) {
      return [
        ...discoverEventsResponse.data.content,
        null,
      ];
    }

    return discoverEventsResponse.data.content;
  }, [discoverEventsResponse, discoverEventsTotal]);

  const profileInfo = useMemo(() => {
    const fullName = formatUserName({
      lastName: currentUser?.lastName || '',
      firstName: currentUser?.firstName || '',
      middleName: currentUser?.middleName || '',
    })

    const isOfficialMember = currentUser?.membershipType === 'OFFICIAL'

    const types = [
      currentUser?.type,
      ...(Array.isArray(currentUser?.types) ? currentUser.types : []),
    ].filter(Boolean) as Array<UserType>;

    const userTypes: UserTypesInformation[] = types.map((userType) => {
      return {
        label: userType,
        value: userType.toLowerCase(),
      };
    });

    return {
      fullName,
      isOfficialMember,
      userTypes
    }

  }, [currentUser]);

  const handleNext = () => {
    setItemCarousel(carouselItem + 1);
    carouselRefEvents.current?.slideNext();
  };

  const handleBack = () => {
    setItemCarousel(carouselItem - 1);
    carouselRefEvents.current?.slidePrev();
  };

  const handleGoToUpgradeMembership = () => {
    history.push('/v3/profile/upgrade-membership');
  };

  const handleRedirectToDiscoverEvents = useCallback(() => {
    history.push('/v3/events?tab=discover-events');
  }, [history]);

  return (
    <S.Container>
      {!!missingInformation?.length && (
        <AccountStatus />
      )}
      <S.ContainerUser>
        <S.ContainerUserProfile>
          <S.UserProfileImage
            size={160}
            icon={<UserOutlined />}
            src={ConvertUtil.getMinioUrl(currentUser.photo)}
          />
          <S.UserInformation>
            <S.UserNameAndTeams>
              <TitleH2>{profileInfo.fullName}</TitleH2>
            </S.UserNameAndTeams>
            {!profileInfo.isOfficialMember && (
              <OutlinedButton color='primary' isBold={false} isUpper onClick={handleGoToUpgradeMembership}>
                Upgrade Membership
              </OutlinedButton>
            )}
          </S.UserInformation>
        </S.ContainerUserProfile>
        <UserXPInformation userOptions={profileInfo.userTypes} />
      </S.ContainerUser>
      <S.ContainerEvents>
        <S.EventsContent className={joinedEventsTotal <= 1 ? 'full-width' : ''}>
          <div>
            <TitleH3>Events</TitleH3>
            <TitleH4 $color='grey400'>My Events</TitleH4>
            <S.MyEventsContainer>
              {joinedEvent && (
                <>
                  <EventCard
                    id={joinedEvent.id}
                    title={joinedEvent.name}
                    startDate={joinedEvent.startDate}
                    finalDate={joinedEvent.endDate}
                    logo={joinedEvent.logo}
                    venue={joinedEvent.venues.length ? joinedEvent.venues[0] : null}
                    isGreen
                  />
                  {joinedEventsTotal > 1 && (
                    <S.EventMore>
                      <span>+{joinedEventsTotal - 1}</span>
                    </S.EventMore>
                  )}
                </>
              )}
              {!joinedEvent && (
                <S.EmptyEventCard><BodyM>Your events are missing</BodyM></S.EmptyEventCard>
              )}
            </S.MyEventsContainer>
          </div>
          <div>
            <S.OtherEventsTitleAndControllers>
              <TitleH4 $color='grey400' style={{ marginRight: 16 }}>
                Other Events
              </TitleH4>
              <S.ButtonCarousel
                disabled={carouselItem <= 1}
                onClick={handleBack}
              >
                <LeftSmall size={16} />
              </S.ButtonCarousel>
              <S.ButtonCarousel
                disabled={discoverEvents.length <= 2 || carouselItem + 2 >= discoverEvents.length}
                onClick={handleNext}
              >
                <RightSmall size={16} />
              </S.ButtonCarousel>
            </S.OtherEventsTitleAndControllers>
            <S.OtherEventsContainer className={joinedEventsTotal <= 1 ? 'full-width' : ''}>
              {!!discoverEvents.length && (
                <AliceCarousel
                  responsive={EVENTS_CAROUSEL_RESPONSIVE}
                  ref={carouselRefEvents}
                  disableSlideInfo
                  autoWidth
                >
                  {discoverEvents.map((event) => {
                    if (!event) {
                      return (
                        <S.SlideItem>
                          <S.SeeMoreEventCard onClick={handleRedirectToDiscoverEvents}>
                            <BodyMBold>See More</BodyMBold>
                          </S.SeeMoreEventCard>
                        </S.SlideItem>
                      )
                    }

                    return (
                      <S.SlideItem>
                        <EventCard
                          key={event.id}
                          id={event.id}
                          title={event.name}
                          startDate={event.startDate}
                          finalDate={event.endDate}
                          logo={event.logo}
                          venue={event.venues.length ? event.venues[0] : null}
                        />
                      </S.SlideItem>
                    )
                  })}
                </AliceCarousel>
              )}
              {!discoverEvents.length && (
                <S.EmptyEventCard><BodyM>Other events are missing</BodyM></S.EmptyEventCard>
              )}
            </S.OtherEventsContainer>
          </div>
        </S.EventsContent>
      </S.ContainerEvents>
    </S.Container>
  );
};

export default Home;
