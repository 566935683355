export const optionsArray = [
  {
    value: 'Letters (A, B, C…)',
    label: 'Letters (A, B, C…)',
  },
  {
    value: 'Numbers (1, 2, 3…)',
    label: 'Numbers (1, 2, 3…)',
  },
];
