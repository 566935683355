import React from 'react';
import InputText from 'components/v3/Forms/InputText/InputText';
import { BodyLBold, BodyM, BodyMBold } from 'styles/v3/variables';
import InputCheckbox from 'components/v3/Forms/InputCheckbox/InputCheckbox';
import Select, { OptionsType } from 'components/v3/Forms/Select/Select';
import { coachesOptions } from '../../constants';

import * as MainStyles from '../../styles';

type CoachesRulesProps = {
  allowCoachTeam: boolean;
  allowCoachClub: boolean;
  minimumCoachesNumber?: number;
  maximumCoachesNumber?: number;
  setLabelToCoachesPermission: OptionsType | undefined;
  setAllowCoachClub: React.Dispatch<React.SetStateAction<boolean>>;
  setAllowCoachTeam: React.Dispatch<React.SetStateAction<boolean>>;
};

export const CoachesRules = ({
  allowCoachTeam,
  allowCoachClub,
  setAllowCoachClub,
  setAllowCoachTeam,
  maximumCoachesNumber,
  minimumCoachesNumber,
  setLabelToCoachesPermission,
}: CoachesRulesProps) => {
  return (
    <fieldset>
      <legend>
        <BodyLBold $color='white'>Coaches Rules</BodyLBold>
      </legend>
      <InputText
        containerClassName='general-rules-input'
        id='minCoachesRules'
        placeholder='Min Number of Coaches'
        label='Min Number of Coaches'
        defaultValue={minimumCoachesNumber}
        type='number'
        min={0}
      />
      <InputText
        containerClassName='general-rules-input'
        id='maxCoachesRules'
        placeholder='Max Number of Coaches'
        label='Max Number of Coaches'
        defaultValue={maximumCoachesNumber}
        type='number'
        min={0}
      />
      <BodyMBold $color='grey200'>Permissions</BodyMBold>
      <MainStyles.ElementCheckboxWrapper>
        <InputCheckbox
          id='allowCoachesOnDifferentTeams'
          name='allowCoachesOnDifferentTeams'
          onChange={() => setAllowCoachTeam(!allowCoachTeam)}
          checked={allowCoachTeam}
        />
        <BodyM>
          Allow coaches to coach in{' '}
          <BodyMBold $color='brandPrimary'>different Teams</BodyMBold>
        </BodyM>
      </MainStyles.ElementCheckboxWrapper>
      {allowCoachTeam && (
        <>
          <MainStyles.ElementCheckboxWrapper>
            <InputCheckbox
              id='allowCoachesOnDifferentClubs'
              name='allowCoachesOnDifferentClubs'
              onChange={() => setAllowCoachClub(!allowCoachClub)}
              checked={allowCoachClub}
            />
            <BodyM>
              Allow coaches to coach in{' '}
              <BodyMBold $color='brandPrimary'>different Clubs</BodyMBold>
            </BodyM>
          </MainStyles.ElementCheckboxWrapper>
          <Select
            name='coachAgeDivisionPermission'
            placeholder='Select Age Division Permission'
            defaultValue={setLabelToCoachesPermission}
            options={coachesOptions}
          />
        </>
      )}
    </fieldset>
  );
};
